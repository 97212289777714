.chat-wrapper {
    display: flex;
    padding: 40px 20px;
    gap: 0;
    min-height: 400px;
    margin: 0 auto;
    background-color: #EAEFF3;
    transition: all 0.4s;
}

#travelers-list-container {
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    height: 650px;
    overflow-y: auto;
}

#chat-header {
    font-weight: 600 !important;
    text-align: left !important;
    font-size: 1.5em !important;
    margin-top: 20px;
    margin-bottom: 20px;
    /* padding-left: 10px; */
}

.chat-search-field {
    /* padding-left: 10px; */
    /* padding-right: 10px; */
}

.chat-search-field input {
    border-radius: 20px;
    padding-left: 30px;
}

.chat-tabs-list {
    padding-bottom: 15px !important;
    padding-top: 15px !important;
}

.chat-tabs-list>li {
    margin-right: 40px !important;
}

.chat-tabs-list>.react-tabs__tab.react-tabs__tab--selected {
    position: relative;
}
.chat-tabs-list>.react-tabs__tab.react-tabs__tab--selected::after{
    content: ""; position: absolute; bottom: -4px; left: 0;
    background:#536EDA; width: 24px; height: 4px; border-radius: 1rem;
}

.chat-user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 5px 0;
    border-left: 4px solid white;
}

.chat-user-info.selected,.chat-user-info:hover, .chat-user-info:active, .chat-user-info:focus {
    background-color: #EDEFF7;
    border-left: 4px solid #536EDA;
}
.listGroups .chat-user-info.selected, .listGroups .chat-user-info:hover, .listGroups .chat-user-info:focus, .listGroups .chat-user-info:active{
    border-color: #8A288F;
}
#chat-box-container {
    background-color: #f8f6f6;
    padding: 15px;
    border-radius: 10px;
    height: 650px;
}

.conversation-container {
    padding-top: 40px;
}
.groupTravelers .conversation-container::-webkit-scrollbar{width: 6px;}
.groupTravelers .conversation-container::-webkit-scrollbar-track{background-color: transparent;}
.groupTravelers .conversation-container::-webkit-scrollbar-thumb{background-color: #8A288F;}
.groupTravelers .conversation-container{padding-top: 0;overflow-y: auto;padding-right: 15px;max-height: 83%;}
.messageSender, .messageSenderPurple{
position: relative; margin: 5px 5px 5px 10px;border-radius: 0 4px 4px 4px; max-width: 94%; width: 100%; padding: 5px;
}
.messageSender::before, .messageSenderPurple::before{
    content: ""; position: absolute; top: 0; left: -10px; width: 10px; border-top: 10px solid #F2F2F2;
    border-left: 10px solid transparent;
}
.messageSenderPurple{margin: 5px 15px 5px 0;border-radius: 4px;}
.messageSenderPurple::before{
    border-right: 10px solid transparent; border-top: 10px solid #8A288F; left: auto; right: -9px;
}
.chatPurple, .chatGrey{display: block; width: 100%; max-width: 94%;}
.chatPurple{text-align: end;}
.chatGrey{text-align: start;}