.deleteFolder {
    position: relative;
    left: 86%;
    top: 36px;
    z-index: 1000;
    cursor: pointer;
}


/* div{
    height:50 px  ;
} */