/* <link
          rel="stylesheet"
          href="https://db.onlinewebfonts.com/c/a78cfad3beb089a6ce86d4e280fa270b?family=Calibri"
          // href="https://db.onlinewebfonts.com/c/f4221c695de0fe4bd63bf82813b53175?family=MS+Gothic"
          // href="https://db.onlinewebfonts.com/c/67680f2eb947c5cbd58b40961b4a61e9?family=Apple+SD+Gothic+Neo+Bold"
          integrity="sha384-Xi8rHCmBmhbuyyhbI88391ZKP2dmfnOl4rT9ZfRI7mLTdk1wblIUnrIq35nqwEvC"
          crossorigin="anonymous"
        /> */
@import "https://db.onlinewebfonts.com/c/a78cfad3beb089a6ce86d4e280fa270b?family=Calibri";
html,
body {
  font-size: 14px;
  font-family: "Calibri", -apple-system !important;
}

#MyLiveChatContainer img {
  border-radius: 0.1em;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

img {
  max-width: 100%;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #09ccae;
}
/* .printText{position:absolute;right:0.25in;top:5.15in;max-width:5in}
.printBarcode{position:absolute;right:0.1in;top:10.15in;max-width:5in} */

.printText {
  position: absolute;
  right: 0;
  top: 5.12in;
  max-width: 4in;
}

.printBarcode {
  position: absolute;
  right: 0.05in;
  top: 10.15in;
  max-width: 4in;
}

.shippingCheck {
  color: white;
  background: darkgreen;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  /* padding: 5px; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.shippingAddress {
  background-color: #efefef;
  padding: 5px;
  margin: 0px;
}

.refundStyle {
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: column;
}

@media print {
  .printText {
    right: 0;
    top: 3.2in;
    max-width: 4in;
  }
  .shipping {
    right: 0;
    top: 3.2in;
    max-width: 10in;
  }
  .printBarcode {
    right: -0.2in;
    top: 6.1in;
    max-width: 4in;
  }
  .printText {
    -webkit-margin-before: 0.1in;
  }
  .printBarcode {
    -webkit-margin-before: 0.1in;
  }
  /* .printText{right:0;top:29.75%;max-width:4in}
    .printBarcode{right:-15px;top:57.5%;}
    .printText{-webkit-margin-before: 2.9%}
    .printBarcode{-webkit-margin-before: 5.5%} */
  @page {
    /* size: letter portrait; */
    /* width: 100%;
        max-width: 100%;
        height: 100%;
        max-width: 100%; */
  }
}
#header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
}
.containerHeader,
.containerFooter {
  width: 100%;
  margin: 0 auto;
}
.dashboardContainer {
  padding-top: 90px;
}
.textMDCenter {
  text-align: start;
}
@media (min-width: 768px) {
  #csrDialogue .modal-dialog {
    width: 100%;
    margin: 30px auto;
    max-width: 1000px;
  }
  #showContacts .modal-dialog {
    width: 70%;
    /* margin: 30px auto;
        max-width: 1000px; */
  }
  #header .containerHeader,
  #footer .containerFooter {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
  }
  /* .container {
    max-width: 744px;
    width: 100%;
  } */
  .textMDCenter {
    text-align: center;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    line-height: 1.25;
    text-align: center;
    gap: 12px;
  }
  .col-span-4 {
    grid-column: span 4 / span 4;
  }
}

.pagesLink {
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 18px;
}
.bgImage {
  display: block;
  margin: 0 auto !important;
}
.section-frontEnd .bgDark {
  background-color: #3b3d44;
}
.section-frontEnd .socialWrapper {
  border-bottom: 1px solid #444b56;
  padding: 0.5rem 1rem;
  margin: 0 auto;
}
.section-frontEnd .socialLinks {
  display: flex;
  justify-content: center;
  gap: 15px;
  transition: all 0.4s ease-in-out;
}
.section-frontEnd .socialLinks a {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: all 0.4s ease-in-out;
}
.section-frontEnd .socialIcon img:last-child {
  display: none;
}
.section-frontEnd .socialIcon:hover img:first-child {
  display: none;
}
.section-frontEnd .socialIcon:hover img:last-child {
  display: flex;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  line-height: 1.25;
  text-align: center;
  gap: 12px;
}
.col-span-4 {
  grid-column: span 6 / span 6;
}
.anchor {
  color: white;
}
.anchor:hover {
  color: #fa456e;
}
.section-frontEnd .footerWrap {
  padding: 2.5rem 1rem;
  margin: 0 auto;
}
.section-frontEnd .footerGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.section-frontEnd .footerCopyrights {
  padding: 1.25rem 1rem;
  border-top: 1px solid #444b56;
}
.section-frontEnd .footerCopyrightsInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.section-frontEnd .textGreen {
  color: #09ccae;
}
.externalLinks {
  padding-left: 50px;
}
@media (max-width: 1024px) {
  .section-frontEnd .footerGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .sideNav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 70%;
    z-index: 100;
    padding-top: 60px;
  }
  .sideNav.navbar-collapse.collapse {
    min-height: 100vh !important;
  }
  .externalLinks,
  .pagesLink {
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .section-frontEnd .footerGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .section-frontEnd .navbar .nav li a,
  .section-frontEnd .no_slider .affix.navbar-default .navbar-nav > li > a {
    color: #000 !important;
    opacity: 1;
  }
}
.react-date-picker__calendar {
  z-index: 101;
}

.starsLink {
  color: #1684e5 !important;
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.underline:hover {
  text-decoration: underline;
}

.yellowBackgroud {
  background-color: yellow;
}

.static .tooltip-inner {
  max-width: 450px !important;
  font-size: 10px;
}

.schedulerHeading {
  color: #fd5656;
  /* font-family: "GOTHICB", sans-serif; */
  font-size: 24px !important;
  text-align: center;
  margin-top: 20px !important;
  /* justify-content: center; */
}
.shippingHeading {
  /* font-family: "GOTHICB", sans-serif; */
  font-size: 24px !important;
  text-align: center;
  margin-top: 20px !important;
  /* justify-content: center; */
}

.myLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#csrDialogue .modal-content {
  height: 8in;
}

#csrDialogue .card.innerCard {
  height: calc(100% - 160px);
}

#csrDialogue .card .content,
#csrDialogue .cke_voice_label,
#csrDialogue .cke {
  height: 100%;
}

#csrDialogue .cke_contents {
  height: calc(100% - 136px) !important;
}

.myLoader img {
  -webkit-animation: spin 2s linear infinite reverse;
  /* Safari */
  animation: spin 2s linear infinite reverse;
}

.selectTimeZone {
  background-color: white;
  padding: 20px;
  color: black;
  min-width: 320px;
}

.lprFontBold {
  font-weight: bold;
}

.secondary {
  color: #233c74 !important;
}

.whiteBG {
  background-color: white;
}

.py25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.px1-25 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.lineH {
  line-height: 1.1;
}

.border1 {
  border: 1px solid #e6e6e6 !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.orderTableWeight thead th {
  font-size: 14px !important;
}

.orderTableWeight tbody td {
  font-size: 13px !important;
  padding: 4px 15px !important;
}

.disabledUnprocess {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}

.gallery_view_box {
  background: #e1eaf1;
  border: 1px solid #d3d6dc;
  margin-top: 15px;
  padding-top: 20px;
  /* padding-bottom: 30px; */
  position: relative;
}

.section-frontEnd
  #uploaderTabs
  .tabsContainer
  .tabsHeader
  .pGtabsHeadings.pGtabActive {
  zoom: 1;
  color: #42d0ed;
  text-decoration: underline;
}

.section-frontEnd .blue_theme_button {
  background-color: #fa456e;
}

.pGtabsHeadings {
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1.1;
  font-weight: 600 !important;
  font-size: 1rem !important;
  text-transform: none;
  cursor: pointer;
  color: #333;
}

.mw100Force {
  max-width: 100% !important;
  width: 100% !important;
  min-width: 100%;
}

.tab3mw100Force {
  max-width: 71% !important;
  width: 100% !important;
  /* min-width: 100%; */
}

.pgTab3 {
  text-align: center;
}

.gallery_view_box_heading {
  color: #394e81;
  font-weight: bold !important;
  font-size: 18px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}
.mb10F {
  margin-bottom: 10px !important;
}

.mb0f {
  margin-bottom: 0 !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #fa456e;
}
.roundedOverlay {
  background-color: #fff;
  width: 100px;
  height: 100px;
  object-fit: contain;
  padding: 15px;
  border-radius: 10rem;
  margin: -50px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.roundedOverlay_home {
  background-color: #002d5e;
  width: 100px;
  height: 100px;
  object-fit: contain;
  /* padding: 15px; */
  border-radius: 10rem;
  margin: -50px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  opacity: 0.8;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}

.mt-25p {
  margin-top: 25px;
}

.csr .btn-link {
  cursor: not-allowed;
}

.redText {
  color: tomato !important;
}

.lprRedText > p {
  color: #ff4a55 !important;
}

.fto {
  color: #ff4a55 !important;
  font-size: xx-large !important;
}

.right {
  float: right;
}

.addFolderLink {
  float: right;
  text-decoration: underline !important;
  cursor: pointer;
}

.linkBtn {
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  font-size: 18px;
  vertical-align: middle;
  outline: none !important;
}

.notAllowed {
  cursor: not-allowed !important;
}

.disabledTr {
  background: #ccc;
}

.transparentColor::-webkit-color-swatch {
  background-color: transparent !important;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}

.gtvIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.gtvIcons img {
  width: 120px;
}

.appSettings_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/app-settings.png")
    no-repeat 10px center;
  padding-left: 30px;
}

.faqs_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/faqs.png")
    no-repeat 10px center;
  padding-left: 30px;
}

.generalSettings_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/general-settings.png")
    no-repeat 10px center;
  padding-left: 30px;
}

.selectIndexFix > div {
  z-index: 1000;
}

.visibilty_hidden {
  visibility: hidden;
}

#nprogress .spinner {
  display: block;
  position: fixed;
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/main_loader.gif")
      no-repeat center,
    rgba(255, 255, 255, 0.5);
  z-index: 1031;
  top: 0px;
  right: 0px;
  left: 0;
  bottom: 0;
}

/*start Barcode color css with priority*/

.barcode-color-yellow {
  color: yellow;
}

.barcode-color-red {
  color: red;
}

/*end Barcode color css with priority*/

.backbutton + .content {
  padding-top: 0;
}

.backbutton {
  padding: 0 29px;
}

div.backbutton {
  margin-top: 15px;
}

.backbutton .backButton {
  background: rgba(63, 63, 68, 0.1) !important;
  color: #666 !important;
  margin-bottom: 1px;
}

.backbutton .backButton img {
  transform: rotate(90deg);
}

.backbutton .backButton:hover img {
  animation: back 0.3s ease-in infinite alternate;
}

.initialsLogo {
  display: inline-flex;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}

#editOrder .modal-dialog {
  max-width: 700px;
}

#editOrderAssets .modal-dialog {
  max-width: 1000px;
}

.assignAssetsLink {
  font-size: 12px;
}

#editOrder .modal-dialog .table > tbody > tr > td {
  vertical-align: top !important;
}

.orderStatus > div + div {
  margin-left: 5px;
}

.orderStatus .cancelled {
  color: red;
  margin-left: 10px;
}

.orderStatus .onHold {
  color: orange;
  margin-left: 10px;
}

.orderStatus .unresolved {
  color: purple;
  margin-left: 10px;
}

#nprogress .spinner * {
  display: none !important;
}

.display_ib {
  display: inline-block !important;
}

#tabs li.selected a {
  color: blue !important;
}

.card {
  border-radius: 0;
}

.card label {
  text-transform: capitalize;
}

.card .captilize-reset {
  text-transform: none;
}

.card.innerCard {
  border: none;
  box-shadow: none;
  background: #eaeef1;
  border-radius: 0;
}

.customBtn,
.customBtn:hover,
.customBtn:focus,
.customBtn:active {
  background: #2f4576;
  color: #fff !important;
  border: none;
}

.customBtnRedTxt,
.customBtnRedTxt:hover,
.customBtnRedTxt:focus,
.customBtnRedTxt:active {
  background: #2f4576;
  color: #e34848;
  border: none;
  font-weight: 600;
}

.mi {
  font-size: 10px;
}

.customBtn.btn-danger {
  background: #ff4a55;
}

.headerUserIcon {
  vertical-align: bottom;
  height: 28px;
}

.dropdown-toggle {
  cursor: pointer;
  font-weight: 300;
}

.headerLogin {
  margin-top: 10px;
}

.headerLogin .dropdown-menu {
  border-radius: 0;
}

.headerLogin.open .dropdown-menu {
  margin-top: 11px;
}

.headerLogin .dropdown-menu li,
.headerLogin .dropdown-menu li a {
  border-radius: 0;
}

.headerLogin .dropdown-menu li a img {
  margin-left: 5px;
}

.sideNavToggle.navbar-link {
  margin-top: 22px;
}

.react-datepicker-popper {
  z-index: 1000;
}

.fullVideo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
}

.flexElem.datesContainer > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flexElem.datesContainer > div + div .form-control {
  border-left: none !important;
}

.react-date-picker .react-date-picker__wrapper {
  border: none;
  font-size: 14px;
}

.customBtn[disabled] {
  background: #999;
  color: #fff;
  border: none;
}

.customBtn[disabled]:hover {
  background: #ccc;
  color: #fff;
  border: none;
}

.orderSettingText p.text-muted {
  font-size: 14px;
  line-height: 1.6;
  margin-top: 10px;
}

.title.headerTitle.collapsClosed {
  padding-bottom: 20px;
}

.card.innerCard .header .title.headerTitle {
  padding-left: 10px;
  margin-top: 12px;
  font-size: 17px;
}

.flexStyling {
  padding: 0;
  display: inline-flex;
  justify-content: center;
  text-align: left;
  width: 100%;
}

.flex-center {
  justify-content: center;
}

.flexStyling .flexStylingChild {
  padding: 10px 15px;
}

.permission_label {
  width: 100%;
  height: 45px;
  background-color: #f8fcff;
  border: 1px solid #f8fcff;
  padding: 12px;
  margin: -5px;
  resize: none;
  overflow-y: hidden;
}

.search_field {
  margin-left: 20px;
  margin-top: -5px;
  margin-bottom: 3px;
  font-weight: normal;
  text-align: left;
  height: 40px !important;
  resize: none;
  overflow-y: hidden;
}

.permission_label:focus,
.permission_label:hover {
  border-color: #ddd;
  background: #fff;
}

.permissions tr th,
.permissions tr td {
  text-align: center;
}

.permissions tr td img {
  cursor: pointer;
  width: 13px;
}

.permissions thead tr th:first-child,
.permissions tbody tr td:first-child {
  text-align: left;
  background: #f8fcff;
  /*width: 40%;*/
  font-weight: 600;
}

.subNavi {
  position: fixed;
  top: 52px;
  right: 0;
  z-index: 1000;
  transition: all 0.3s;
}

.subNavi.fixedNavi {
  top: -10px;
}

.subNavi .backButton:last-child {
  background: #d94c3f !important;
}

.subNavi .backButton {
  margin-left: 0 !important;
}

.react-datepicker-wrapper,
.react-datepicker-wrapper div {
  width: 100%;
}

.container-fluid .form-group {
  margin-bottom: 10px;
}

.flexStyling .flexStylingChild:nth-child(even) {
  border-left: 1px solid #ccc;
}

.flexStyling .flexStylingChild:last-child {
  border-left: 1px solid #ccc;
}

.customTable.tableLayoutFix.table tbody tr td {
  word-break: keep-all !important;
}

.tableLayoutFix {
  table-layout: fixed;
  width: 100%;
}

#nprogress .bar {
  background: red !important;
  z-index: 1000000000 !important;
}

.arrow_toggle {
  border: none;
  background: none;
  padding: 3px 10px;
  margin-right: 6px;
  float: left;
  border-radius: 0;
  margin-top: 7px;
  border-right: 1px solid #ccc !important;
}

.arrow_toggle > img {
  width: 16px;
}

.form-control {
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
}

#add_agency .noHeightFormGroup .form-group {
  min-height: 10px;
}

[class$="-control"]:not(.form-control) {
  min-height: 40px;
  border-radius: 0;
}

textarea.form-control[name="mosaic_letter_text"] {
  height: 48px;
}

.header .title.headerTitle {
  padding-left: 39px;
  margin-top: 20px;
}

[class^="css-"] {
  border-radius: 0 !important;
}

.arrow_toggle img {
  transition: 0.3s all;
}

.arrow_toggle .arrow_up {
  transform: rotate(180deg);
}

#nprogress .peg {
  box-shadow: 0 0 10px red, 0 0 5px red !important;
}

#nprogress .spinner {
  z-index: 1000000000 !important;
}

#nprogress .spinner-icon {
  border-top-color: red !important;
  border-left-color: red !important;
}

.modal-header .close {
  background: tomato;
  opacity: 0.8;
  color: #fff;
  width: 21px;
  line-height: 20px;
  display: inline-flex;
  border-radius: 100%;
  outline: none;
  align-items: center;
  justify-content: center;
}

.modal-header .close:hover {
  background: tomato;
  opacity: 1;
  color: #fff;
}

.css-df17o1 {
  background: rgba(255, 255, 255, 0.7);
}

.password_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/password.png")
      no-repeat 10px center,
    #fff;
  padding-left: 30px;
}

.image_icons {
  background: url("./images.png") no-repeat 3px center;
  padding-left: 25px;
}

.giudeTrans {
  padding: 10px;
  font-size: smaller;
  color: #666;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 0;
  margin-left: 20px;
  margin-top: 20px;
}

[name="transmittal_letter_text"],
[name="mosaic_letter_text"] {
  min-height: 178px;
}

.giudeTrans li {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.prevVidContainer {
  background: #eee;
  padding: 10px;
  margin: 10px;
  width: 100%;
  max-width: 320px;
  border: 1px solid #fff;
  position: relative;
  transition: all 0.3s;
}

.prevImgContainer {
  background: #eee;
  padding: 10px;
  margin: 10px;
  width: 120px;
  border: 1px solid #fff;
  position: relative;
  transition: all 0.3s;
}

.prevImgContainer:hover,
.prevVidContainer:hover {
  border: 1px solid tomato;
}

.prevImgContainer .prevRemove,
.prevVidContainer .prevRemove {
  position: absolute;
  top: -7px;
  right: -7px;
}

.prevImgContainer .prevRemove > span,
.prevVidContainer .prevRemove > span {
  display: inline-block;
  height: 15px;
  width: 15px;
  background: tomato;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  border-radius: 100%;
  text-align: center;
  line-height: 15px;
}

.prevImgContainer .prevName,
.prevVidContainer .prevName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploadImages {
  flex-wrap: wrap;
  justify-content: center;
  max-height: 400px;
  overflow-y: auto;
}

.uploadImages > span {
  flex-basis: 25%;
}

.lbl_container {
  text-align: left;
  padding-top: 0 !important;
  padding-left: 10px !important;
  padding-bottom: 0 !important;
  align-items: center;
}

.lbl_container label {
  margin-bottom: 0;
}

#add_agency .form-group {
  margin-bottom: 2px;
  min-height: 90px;
}

#add_agency .srv-validation-message {
  position: absolute;
}

.backButton {
  color: #fff !important;
  border: none !important;
  opacity: 1;
}

.backButton:hover {
  -webkit-filter: saturate(1.6);
  filter: saturate(1.6);
}

.backButton.success,
.backButton.success:focus,
.backButton.success:active {
  background: #4dc19c;
}

.backButton.warning,
.backButton.warning:focus,
.backButton.warning:active {
  background: #f29d21;
}

.backButton.danger,
.backButton.danger:focus,
.backButton.danger:active {
  background: #cd412f;
}

.backButton.primary,
.backButton.primary:focus,
.backButton.primary:active {
  background: #2f4576;
}

.backButton.info,
.backButton.info:focus,
.backButton.info:active {
  background: #20ace5;
}

.customTable.table thead tr th {
  white-space: nowrap;
  background: #2f4576 !important;
  color: #fff;
  font-weight: 500;
}

.search-input > input {
  width: inherit;
  height: inherit;
  box-sizing: border-box;
  margin-top: -9px;
  border: none;
  border-top: 1px solid hsl(0, 0%, 80%);
  border-bottom: 1px solid hsl(0, 0%, 80%);
  outline: none !important;
}

.customTable.table tbody tr td.dontWrap {
  white-space: nowrap !important;
}

.customTable.table tbody tr td {
  /* border-bottom-width: 1px !important;
  font-size: 12px !important; */
  /*text-transform: uppercase !important;*/
  color: #9a9a9a !important;
  /*font-weight: 400;*/
  padding-bottom: 5px !important;
  word-break: break-all !important;
}

.email_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/email_icon.png")
      no-repeat 10px center,
    #fff;
  padding-left: 30px;
}

.phone_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/phone_icon.png")
      no-repeat 10px center,
    #fff;
  padding-left: 30px;
}

.fax_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/fax_icon.png")
      no-repeat 10px center,
    #fff;
  padding-left: 30px;
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  color: #fff;
}

label {
  color: #5d5d5d !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.assign-label {
  color: #2f4576 !important;
  font-weight: 500 !important;
  font-size: 12px;
}

.headerPagination {
  display: none !important;
}

.actionBtn {
  padding: 5px 7px;
  border: none;
  background: #eee;
  color: #333;
  display: inline-flex;
  align-items: center;
  outline: none;
  border-radius: 4px;
}

.actionBtn svg {
  font-size: 16px;
  margin-right: 5px;
}

.actionBtn.primaryBtn {
  background: #fa456e;
  color: #fff;
}

.actionBtn.primaryBtn.inverse {
  background: #fff;
  color: #2f4576;
}

.actionBtn.infoBtn {
  background: #1684e5;
  color: #fff;
}

.question_mark {
  display: inline-block;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  background: #fff;
  color: #999;
  border-radius: 100%;
  margin-left: 20px;
  cursor: default;
  border: none;
}

.question_mark:hover {
  background: #2f4576;
  color: #fff;
}

.badge {
  margin: 5px !important;
}

.sidebar .nav p {
  text-transform: capitalize;
  font-weight: 400;
}

.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  text-transform: capitalize !important;
}

.CustomHeader {
  background-color: #2f4576;
}

.relativeCard {
  position: relative;
}

.ReactTable,
.customTable {
  color: #666;
}

.ReactTable .rt-thead {
  background-color: #5bab9f !important;
  /* background-color: #fa456e !important; */
  color: #ffffff;
}

.ReactTable.-striped .rt-tr.-even {
  background: #f5f5f5;
}

/* .ReactTable.-striped .rt-tr.-even .commentUserDate{
  border-color: #ccc;
} */

.ReactTable.-striped .rt-tr.-odd {
  background: #fff;
}

.btn-toolbar .action-button-size,
.form-group .outOfTableButton,
button.outOfTableButton,
.form-group .outOfTableButton:active,
button.outOfTableButton:active {
  color: #363c77 !important;
  background: transparent !important;
}

.content a,
#tooltip a {
  color: #444;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-color: rgba(255, 255, 255, 0.2);
}

.ReactTable .rt-thead.-headerGroups {
  border-color: rgba(255, 255, 255, 0.2);
}

input {
  color: #333 !important;
}

.inputCustom,
.inputCustom > div {
  background-color: #e8edf2 !important;
  color: #333 !important;
  z-index: 1000;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a0a0a0 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a0a0a0 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a0a0a0 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #a0a0a0 !important;
}

.headerTitle {
  color: #2f4576 !important;
  font-weight: 500 !important;
}

.navbar-brand a {
  color: #ffffff;
}

.navbar-nav a {
  color: #ffffff !important;
}

.navbar-default .navbar-link {
  color: #ffffff !important;
}

.rt-tr {
  /*font-size: 10px !important;*/
}

.span-chekBox {
  display: block;
  font-size: 9px;
  line-height: 10px;
  text-transform: capitalize;
}

.span-chekBoxLH {
  display: block;
  font-size: 9px;
  line-height: 3px;
  text-transform: capitalize;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexElem {
  display: flex !important;
}

.flexColumn {
  flex-direction: column;
}
.alignCenter {
  align-items: center;
}
.alignEnd {
  align-items: flex-end;
}

.flexGap10 {
  gap: 10px;
}
.flexGap5 {
  gap: 5px;
}
.socialIconBox {
  width: 32px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 1);
}
.socialIconBox:hover,
.socialIconBox:focus,
.socialIconBox:active,
.socialIconBox:focus-within {
  border-color: #fa456e;
}
.section-frontEnd .footer_menus li a.gmLink:hover,
.section-frontEnd .footer_menus li a.gmLink:focus-within,
.section-frontEnd .footer_menus li a.gmLink:focus,
.section-frontEnd .footer_menus li a.gmLink:active,
.navbar-default .navbar-nav > li > a.groupMemoryMenuHover:not(.btn):hover {
  color: #fa456e !important;
}
.navbar-default
  .navbar-nav
  > li
  > a.groupMemoryMenuHover:not(.btn):hover::after,
.section-frontEnd .footer_menus li a.gmLink:hover::after {
  content: none;
}
.groupMemoryMenuHover:hover,
.groupMemoryMenuHover:active,
.groupMemoryMenuHover:focus,
.groupMemoryMenuHover:focus-within {
  color: #fa456e !important;
}
.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.flexWrap {
  flex-wrap: wrap;
}

.formControlsm {
  height: 30px;
  line-height: 1;
}

.justifyCenter {
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.colorPickerRound {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border: 1px solid #fff;
  margin-bottom: 5px;
}

.colorPickerRound .inputColor {
  height: 40px !important;
  width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

.colorPickerRound .inputColor > span {
  border: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
}

/*.colorPickerRound input{
  display: inline-block;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;
   cursor: pointer;
   transform: scale(1.5);
}*/

.videoUploaderSection {
  align-items: flex-start;
}

.videoUploaderSection > div {
  width: 100%;
}

.blue_label {
  color: #1684e5;
}

.blue_link,
.linkColor {
  color: #1684e5 !important;
  display: inline-block;
}

.showNoDownlaod {
  background: none !important;
}

.blue_link.target_blank {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/external-link-symbol.png")
    no-repeat 0 3px;
  background-size: 16px;
  padding-left: 25px;
  transition: all 0.2s;
}

.blue_link.target_blank:hover {
  background-position: 3% 2px;
}

.blue_link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.orderContent {
  border: 1px solid #ccc;
  margin-top: 30px;
}

.highlight_blue {
  background: #a9d1eb;
}

.highlight_green {
  background: #e3f4d0;
}

.orderTable.table > tbody + tbody {
  border: none;
}

.sepSections .orderContent.hasChild {
  margin-top: 0;
  border: none;
}

.sepSections .orderContent.hasChild .orderContent {
  width: 100%;
}

.secHeading {
  font-size: 20px;
  color: #2f4576;
  margin: 15px 0;
  padding: 0 5px;
}

.orderTopNav {
  font-size: 16px;
  color: #2f4576;
  margin: 25px 0;
}

.orderTopNav .barcodeContainer {
  display: inline-block;
  margin-left: 10px;
  padding: 10px;
  color: #2f9ae4;
}

.sepSections.flexElem {
  justify-content: space-between;
}

.sepSections .orderContent:first-child {
  width: calc(35% - 15px);
}

.sepSections .orderContent:last-child {
  width: calc(65% - 15px);
}

.orderDetailsHeader {
  font-size: 16px;
  color: #2f4576;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.commentsContainer .ReactTable {
  border: none;
}

.editAdmin > div {
  width: 100%;
}

.editAdmin a {
  color: #666;
  cursor: pointer;
}

.last_three_rows tr:nth-last-child(1),
.last_three_rows tr:nth-last-child(3) {
  background: #dde3f1;
}

.last_three_rows tr:nth-last-child(2) {
  background: #f2f5fa;
}

.coomentAvatar {
  border-radius: 100%;
  height: 50px;
  opacity: 1;
}

.commentUserDate {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.commentUserDate > span {
  margin-left: 10px;
}

.commentUserDate > span + span {
  margin-left: 30px;
}

.commentUserDate + div {
  padding-left: 10px;
}

.flexElem.flexResponsive.quickLink input {
  width: 100%;
  background: transparent;
  text-indent: 7px;
  padding-right: 7px;
  color: #1684e5 !important;
  border: none;
  outline: none;
}

.block {
  width: 100%;
}

.col-md-122.noMarginTable.frontEndTable.noBorderTable
  .mt-md.col-md-12
  .mt-md.col-md-12 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.table.blank,
.table.blank tbody tr,
.table.blank tbody tr td {
  border: none;
  padding: 5px;
}

.table.orderStatusContainer thead tr th {
  background: #cae0ee;
  color: #2f4576;
  font-weight: 600;
  font-size: 13px;
}

.table.ttkOrderStatusContainer thead tr th,
.ttkOrderHeaderColor {
  background: #f25e5e;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
}

.agencyLogoContainer {
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  padding: 7px;
  text-align: center;
}

.agencyLogoContainer img {
  max-width: 100%;
  max-height: 170px;
  width: auto;
  height: auto;
}

.agencyLogoContainer .agencyLogo.secondaryLogo {
  width: 80%;
  margin-top: 7px;
}

.orderDetailsAction.button_disabled {
  background-color: transparent !important;
  border: none !important;
  opacity: 0.5;
  cursor: not-allowed;
}

.orderDetailsAction.editAction {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/edit_order_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.orderDetailsAction.copyAction {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/copy.svg")
    no-repeat left center;
  /*url('https://gtvoriginalimages.s3.amazonaws.com/static/img/copy_icon.png') no-repeat left center;*/
  padding-left: 22px;
}

.orderDetailsAction.copyColorAction {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/copy.svg")
    no-repeat left center;
  padding-left: 15px;
  line-height: 1.2;
}

.copyEMails {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 0;
}

.copyEMails span {
  margin-right: 15px;
  font-size: 12px;
}

.hasInputIcons {
  position: relative;
}

.hasInputIcons .inputIcons {
  position: absolute;
  right: 7px;
  top: calc(50% - 19px);
  z-index: 1000;
}

.editComment {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/comments_edit_action.png")
    no-repeat left center;
  padding-left: 22px;
}

.deleteComment {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/comments_delete_action.png")
    no-repeat left center;
  padding-left: 22px;
}

.highlightComment {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/comments_highlight_action.png")
    no-repeat left center;
  padding-left: 22px;
}

.trackIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/tracker_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.editIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/edit_order_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.closeIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/close_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.addIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/add_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.adjustIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/adjust_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.assignIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/assign_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.clearIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/clear_all_icon.png")
    no-repeat left center;
  padding-left: 18px;
}

.finalizeIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/finalize_icon.png")
    no-repeat left center;
  padding-left: 18px;
}

.downloadIconBlue {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_icon_blue.png")
    no-repeat left center;
  padding-left: 22px;
}

.downloadIconWhite {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_icon_white.png")
    no-repeat left center;
  padding-left: 22px !important;
}

.downloadIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.downloadIconHeight {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_circle.png")
    no-repeat left center;
  padding-left: 22px;
  height: 25px;
}

.downloadIcon.downloadVideo {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_video.png")
    no-repeat left center;
  height: 25px;
  padding-left: 16px;
}

.downloadIcon.downloadHighlightVideo {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_highlight_video.png")
    no-repeat left center;
  height: 25px;
  padding-left: 16px;
}

.deleteIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/delete_icon.png")
    no-repeat left center;
  padding-left: 20px;
}

.uploadOnly {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/eq_not_returning.png")
    no-repeat left center;
  padding-left: 22px;
}

.returningIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/returning_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.reprintIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/reprint_lab.png")
    no-repeat left center;
  padding-left: 22px;
}

.flagIcon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/flag_order_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.breakText {
  word-break: break-all;
  word-wrap: break-word;
}

.orderDetailsAction.refreshAction {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/refresh_order_icon.png")
    no-repeat left center;
  padding-left: 22px;
}

.orderDetailsAction.small_icon {
  background-size: 24%;
  padding-left: 16px;
  font-size: 12px;
}

.orderDetailsAction {
  color: #333;
  font-size: 14px;
  cursor: pointer;
  border: none;
  outline: none;
}

.orderDetailsAction[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

.orderDetailsBox {
  border: 1px solid #ccc;
  border-bottom: none;
  position: relative;
}

.border-x-0 {
  border-left: none;
  border-right: none;
}

.sepSections .orderDetailsBox {
  width: 100%;
  border-left: none;
  border-right: none;
}

.orderDate {
  display: inline-block;
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/calender_icon.png")
    no-repeat left center;
  padding-left: 20px;
}

.orderStatusContainer {
  justify-content: space-evenly;
  margin-top: 0;
}

.ttkOrderStatusContainer {
  justify-content: space-evenly;
  margin-top: 0;
}

.orderStatusContainer:last-child {
  margin-bottom: 20px;
}

.ttkOrderStatusContainer:last-child {
  margin-bottom: 20px;
}

.orderStatusContainer > div {
  width: calc(33.333333% - 25px);
  padding: 10px 20px;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}

.ttkOrderStatusContainer > div {
  width: calc(33.333333% - 25px);
  padding: 10px 20px;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
}

.processCamera {
  background: #e3f4d0;
}

.receiveOrder {
  background: #eac3fc;
}

.videoShipment {
  background: #a9d1eb;
}

.awarenessFlyer {
  background: #f9ddb5;
}

.returnEquipment {
  background: #aac9f8;
}

.printOrder {
  background: #f8aac9;
}

.innerLabel {
  font-weight: normal;
  color: #1684e4;
}

.text-medium {
  font-size: 16px !important;
}

.orderDetailsBox.box_small {
  width: calc(35% - 15px);
  border-left: none;
}

.orderDetailsBox.box_large {
  width: calc(65% - 15px);
  border-right: none;
}

.orderDetailsBox .orderTable tr td {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.iconOutlineBtn {
  display: inline-block;
  background: none;
  color: rgb(47, 69, 118) !important;
  box-shadow: 0 0 0 1px rgb(47, 69, 118);
  border-radius: 2px;
  padding: 3px 5px;
  transition: 0.2s all;
}

.iconOutlineBtn i {
  margin-right: 3px;
}

.iconOutlineBtn:hover {
  color: #fff !important;
  background: rgb(47, 69, 118);
}

.customStrips tbody tr:nth-child(odd) {
  background-color: #eee;
}

.orderDetailsBox .orderTable tr td .resetOuterPadding tr td:first-child {
  padding-left: 0;
  padding-right: 5px;
}

.orderDetailsBox .orderTable tr td .resetOuterPadding tr td:last-child {
  padding-right: 0;
  padding-left: 5px;
}

.orderDetailsBox .orderTable tr td.td_heading {
  background: #dde3f1;
  padding-top: 7px;
  padding-bottom: 7px;
}

.orderTabs .react-tabs > ul {
  display: flex;
  border: none;
  align-items: flex-end;
}

.orderTabs .react-tabs > ul > li {
  width: 100%;
  margin: 0 1px;
  text-align: center;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #2f4576;
  border-radius: 2px;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  /* min-height: 56px;
    display: flex;
    flex-direction: column; */
  /* align-items: center;
    justify-content:center;
    gap:3px; */
}

.orderTabs .react-tabs > ul.details_tabs > li {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  display: flex;
  gap: 3px;
}

.orderTabs .react-tabs > ul > li.tabDisabled {
  cursor: no-drop;
  background: #728fd1 !important;
}

.orderTabs .react-tabs > ul > li.react-tabs__tab--selected {
  background: #1684e5;
  padding-top: 13px;
  padding-bottom: 13px;
}

.orderTabs.colored .react-tabs > ul {
  align-items: flex-start;
  min-height: 75px;
}

.orderTabs.colored .react-tabs > ul > li {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0;
}

.orderTabs.colored .react-tabs > ul > li.react-tabs__tab--selected {
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 7px 0 0px #f29d21;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(1),
.alert-info {
  background: #79b6dd !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(2),
.alert-primary {
  background: #6d73ed !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(3),
.alert-danger {
  background: #f181ad !important;
}

.orderTabs.colored .react-tabs > ul > li:nth-child(4),
.alert-warning {
  background: #e6bd82 !important;
}

.orderTabs.colored .customUploader + div > div,
.customUploader + div > div {
  border: 1px solid #bebebe !important;
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/dorpzon_placeholder.png")
      no-repeat center 20px,
    #eaeef6 !important;
  padding-top: 100px;
  padding-bottom: 20px;
}

.orderTabs.colored .customUploader + div > div:hover,
.customUploader + div > div:hover {
  border-style: dashed !important;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
}

.appCardActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.dropzone_profileImage > div > div {
  margin-right: 10px;
  border: 1px dashed #bebebe !important;
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/dorpzon_placeholder.png")
      no-repeat center 20px,
    #fff !important;
  background-size: contain;
  padding: 10px;
  text-align: center;
  min-height: 130px;
  padding-top: 83px;
}

.main_login {
  margin-top: 80px;
}

.pofilePicContainer .content {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pofilePicContainer .content .prevImgContainer {
  margin: 0;
  background: #fff;
}

.blueStyle thead {
  background: #2f4576;
  color: #fff !important;
  font-size: 14px;
}

.blueStyle thead tr td {
  padding: 5px 10px;
  border-bottom: none;
}

.blueStyle tbody tr td {
  border: none;
}

.blueStyle tbody tr:nth-child(odd) {
  background: #fff;
}

.blueStyle tbody tr:nth-child(even) {
  background: #f5f5f5;
}

.dropzone_profileImage > div > div p.hello {
  font-size: 12px !important;
}

.dropzone_profileImage > div > div:hover {
  border-style: dotted !important;
}

.appCardActions > div {
  flex-basis: calc(33.3333% - 10px);
  margin: 5px;
}

.appCardActions > div .appCardBtn {
  padding: 15px;
  min-height: 100px;
  display: flex;
  background: #666;
  color: #fff;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.appCardActions > div .appCardBtn > img {
  margin-right: 10px;
}

.appCardActions > div .appCardBtn > span {
  flex-basis: 70%;
}

.appCardActions > div:nth-child(1) .appCardBtn {
  background: #79b6dd;
}

.appCardActions > div:nth-child(2) .appCardBtn {
  background: #6d73ed;
}

.appCardActions > div:nth-child(3) .appCardBtn {
  background: #f181ad;
}

.appCardActions > div:nth-child(4) .appCardBtn {
  background: #b075dd;
}

.appCardActions > div:nth-child(5) .appCardBtn {
  background: #d9ad6e;
}

.appCardActions > div:nth-child(6) .appCardBtn {
  background: #5e7fc7;
}

.appCardActions > div:nth-child(7) .appCardBtn {
  background: #ed6d6d;
}

.appCardActions > div:nth-child(8) .appCardBtn {
  background: #73ce91;
}

span.td_barcode {
  color: #1684e5;
  display: inline-block;
  width: 100%;
}

span.td_orderId {
  background: #f9aac9;
  display: inline-block;
  width: 100%;
  max-width: 150px;
  text-align: center;
  padding: 5px 7px;
}

.orderDetailsBox .orderTable {
  margin: 10px auto;
}

.orderSettingText .flexElem strong {
  align-self: center;
}

.speech-bubble-container {
  position: absolute;
  top: calc(-9% - 20px);
  right: 5px;
}

.speech-bubble {
  position: relative;
  background: #ff0000;
  border-radius: 5em;
  display: block;
  height: auto;
  width: auto;
  padding: 2px 8px;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 0.7em solid transparent;
  border-top-color: #ff0000;
  border-bottom: 0;
  border-left: 0;
  margin-left: -15%;
  top: calc(100% - 2px);
}

.orderTable.table-striped > tbody > tr:nth-of-type(odd) {
  background: #dde3f1;
}

.orderTable.table-striped > thead {
  background: #e3f4d0;
  color: #333;
}

.orderTable.table-striped > thead > tr > th {
  color: #333;
}

.no_bg {
  background: transparent !important;
}

.orderTable.table-striped.evenly > tbody > tr {
  background: #fff;
}

.orderTable.table-striped.evenly > tbody > tr:nth-of-type(even) {
  background: #dde3f1;
}

.orderSettingText .react-datepicker-wrapper,
.react-datepicker-wrapper div {
  width: auto;
}

.fullCalendar .react-datepicker-wrapper div {
  width: 100%;
}

.orderSettingText .flexElem > *:not(:first-child) {
  margin-left: 10px;
}

.imgIconMarRight {
  margin-right: 5px;
}

.sidebar .nav li > span {
  color: #ffffff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  position: relative;
  display: block;
  padding: 2px 15px;
}

.sidebar .nav li > span button {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/chevron-arrow-down.svg")
    no-repeat center;
  background-size: cover;
  border: none;
  height: 10px;
  width: 10px;
  text-indent: -1000px;
  overflow: hidden;
  vertical-align: middle;
  margin-left: 5px;
  outline: none;
  transition: all 0.3s;
}

.navIcon {
  font-size: 18px;
  margin-right: 5px;
}

.downloadCsv {
  text-align: right;
}

.downloadCsv button {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/download_icon.png")
      no-repeat 13px center,
    #eee;
  border: 1px solid #ccc;
  color: #3b3b3b;
  padding: 10px 13px;
  padding-left: 40px;
  transition: 0.3s all;
}

.downloadCsv button:hover {
  background-color: #ccc;
}

.sidebar .nav li.openSubMenu > span button {
  transform: rotate(180deg);
}

.sidebar .nav li {
  border-radius: 0 !important;
}

.sidebar .nav li.active {
  background: rgba(255, 255, 255, 0.1) !important;
}

.sidebar .nav li > .subnav {
  list-style: none;
  display: none;
  padding-left: 0px;
}

.sidebar .nav li > .subnav li {
  background: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.3s all;
}

.sidebar .nav li > .subnav li:last-child {
  border-bottom: none;
}

.sidebar .nav li > .subnav li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.sidebar .nav li.openSubMenu > .subnav {
  display: block;
  overflow: hidden;
  animation: show 0.5s;
  background: rgba(0, 0, 0, 0.2);
  padding-left: 20px;
}

.sidebar .nav li.openSubMenu > .subnav > li a {
  /*text-transform: lowercase !important;*/
  font-size: 12px;
}

.sidebar .nav li.openSubMenu > .subnav > li a::first-letter {
  text-transform: uppercase !important;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.dateSelector .react-datepicker-wrapper,
.dateSelector .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.all-icons input {
  border: 0;
}

/* The container */

.container-check {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  /*font-size: 12px;*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 2;
}

.container-check.disabled {
  cursor: not-allowed;
}

/* Hide the browser's default checkbox */

.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none !important;
}

/* Create a custom checkbox */

.container-check .checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 22px;
  width: 22px;
  min-width: 22px;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ccc;
}

/* On mouse-over, add a grey background color */

.container-check:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.container-check input:checked ~ .checkmark {
  background-color: #2196f3;
}

.container-check input[disabled] ~ .checkmark {
  background-color: #eee;
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.container-check input:checked[disabled] ~ .checkmark {
  background-color: #2196f36e;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container-check .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container-check.checkbox_down {
  padding-left: 0;
}

.container-check.checkbox_down .checkmark {
  left: 3px;
  position: relative;
  display: block;
}

.loaderCustom {
  display: inline-block;
  margin-bottom: 15px;
}

.innerUiSettingsCard .header .title.headerTitle {
  padding-left: 0;
}

.innerUiSettingsCard ul {
  padding-left: 0;
  list-style: none;
}

.innerUiSettingsCard ul li {
  padding: 5px 7px;
  border-radius: 4px;
}

.innerUiSettingsCard ul li + li {
  margin-top: 10px;
}

.innerUiSettingsCard ul li:nth-child(1) {
  background: red;
  color: #fff;
}

.innerUiSettingsCard ul li:nth-child(2) {
  background: green;
  color: #fff;
}

.innerUiSettingsCard ul li:nth-child(3) {
  background: pink;
  color: #000;
}

.innerUiSettingsCard ul li:nth-child(4) {
  background: lightblue;
  color: #000;
}

/* The container rd */

.container-radio {
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: auto;
}

.container-radio.no_label {
  padding-left: 0;
}

/* Hide the browser's default checkbox */

.container-radio input {
  position: absolute !important;
  opacity: 0;
  z-index: -11;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 22px;
  border-radius: 100%;
  border: 1px solid #ccc;
  width: 22px;
  min-width: 22px;
  background-color: #eee;
}

.container-radio .checkmark {
  position: relative;
  top: 0;
  margin-right: 7px;
}

/* On mouse-over, add a grey background color */

.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.container-radio input:checked ~ .checkmark {
  background-color: #2196f3;
  border-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container-radio .checkmark:after {
  left: calc(50% - 5px);
  top: calc(50% - 5px);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #fff;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

.section-frontEnd .header_options {
  /* --tw-text-opacity: 1;
    color: rgb(0 0 0/var(--tw-text-opacity)) !important;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: Calibri,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,sans-serif!important; */
}
.header_options {
  color: #000;
}
.btn_login {
  background: #fa456e;
  border: 1px solid transparent;
  color: #fff !important;
  width: 100%;
  line-height: 2.2;
  font-size: 15px;
  transition: 0.3s all ease;
}

.btn.btn_danger {
  background: #cc402e;
}

.btn_login:hover,
.btn_login:focus {
  /* box-shadow: inset 0 0 0 100px rgba(0, 0, 0, 0.1); */
  color: #fa456e !important;
  background: transparent !important;
  border-color: #fa456e;
}

.form_tile_row .mw-0 {
  width: 100%;
  max-width: 200px !important;
  display: inline-block;
  font-size: 14px;
  /* font-family: "Poppins", sans-serif; */
  text-transform: uppercase;
  color: #fff;
  padding: 15px 20px;
  border-radius: 50px;
  letter-spacing: 0.5pt;
  transition: 0.3s all;
  border: none;
  text-decoration: none;
  line-height: normal;
  outline: none;
  max-width: 100%;
  cursor: pointer;
}
#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.nav li.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.fixed-plugin {
  position: absolute;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 10px;
  padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
  right: 10px;
  margin-left: auto;
  left: auto;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.footer-dropdown {
  top: -120px !important;
}

.footer-dropdown:before,
.footer-dropdown:after {
  top: 300px !important;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  max-height: 175px;
  margin: 0 auto;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-white,
.badge-white {
  background-color: #ffffff;
}

.fixed-plugin .badge-black,
.badge-black {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-azure,
.badge-azure {
  background-color: #1dc7ea;
}

.fixed-plugin .badge-green,
.badge-green {
  background-color: #87cb16;
}

.fixed-plugin .badge-orange,
.badge-orange {
  background-color: #ffa534;
}

.fixed-plugin .badge-purple,
.badge-purple {
  background-color: #9368e9;
}

.fixed-plugin .badge-red,
.badge-red {
  background-color: #fb404b;
}

.minWidthSelect {
  min-width: 150px !important;
}

.badge-white,
.badge-white a {
  color: #000;
}

.badge-black,
.badge-black a {
  color: #fff;
}

.badge-azure,
.badge-azure a {
  color: #fff;
}

.badge-green,
.badge-green a {
  color: #fff;
}

.badge-orange,
.badge-orange a {
  color: #fff;
}

.badge-purple,
.badge-purple a {
  color: #fff;
}

.badge-red,
.badge-red a {
  color: #fff;
}

.fixed-plugin h5 {
  font-size: 12px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 5px 2px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
}

.fixed-plugin .pro-title {
  margin: 10px 0 5px 0;
  text-align: center;
}

.fixed-plugin #sharrreTitle {
  text-align: center;
  padding: 10px 0;
  height: 50px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}

.fixed-plugin .adjustments-line .switch {
  float: right;
}

.fixed-plugin .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  max-height: 86px;
  overflow: hidden;
  padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
  background-color: transparent;
}

.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
  margin-top: auto;
}

.fixed-plugin .btn-social {
  width: 50%;
  display: block;
  width: 48%;
  float: left;
  font-weight: 600;
}

.fixed-plugin .btn-social i {
  margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
  margin-right: 2%;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  outline: none !important;
}

.disabledComment .btn-link {
  color: #888;
  cursor: not-allowed;
}

.commentSteps {
  padding-right: 20px;
}

.contactLogComments > div:last-child {
  margin-top: 10px;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  opacity: 1;
}

.elementor-background-overlay {
  background-color: transparent;
  background-image: linear-gradient(
    170deg,
    rgba(65, 133, 242, 0.87) 33%,
    #ffffff 100%
  );
  opacity: 0.82;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.bgVideo .videoContainer {
  height: 100%;
  max-height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  position: relative;
}

.bgVideo .videoContainer video {
  width: 100%;
}

.rbc-event-content {
  color: tomato;
}

.bannerContent {
  position: relative;
  transform: translateY(-60%);
  z-index: 1000;
  padding: 0 20px;
}

.bannerContent .inlineGridElem > div:nth-child(1) img {
  box-shadow: -15px 7px 25px -5px rgba(0, 50, 50, 0.7);
  border-radius: 11%;
  height: 600px;
  width: auto;
  max-width: 1000;
}

.bannerContent .gtvLogo + br + h1 {
  font-size: 2.2em;
  color: #fff;
  font-weight: 500 !important;
  margin-top: 20px;
  line-height: 1.1em;
  text-shadow: 2px 0px 17px rgba(0, 0, 0, 0.85);
}

.bannerContent .itsLike {
  color: #1569ae;
  font-size: 2em !important;
  font-weight: 600 !important;
  margin-top: 20px;
}

.bannerContent .itsLike + p {
  font-size: 20px;
}

.bannerContent > .inlineFlexElem > div:nth-child(2) {
  width: 100%;
  max-width: 400px;
}

.wordpressButton {
  font-size: 28px !important;
  background-color: #999;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 1.8em;
  padding: 10px 30px !important;
  border-radius: 4px;
  display: inline-flex !important;
}

.wordpressButton.getInTouch {
  background-color: #38b6ff !important;
  margin-top: 1em;
}

.featuresWhile {
  margin-top: -260px !important;
  padding-bottom: 30px !important;
  position: relative;
  padding-top: 100px !important;
}

.hasDivider.featuresWhile:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 1px;
  background-color: #ccc;
  display: block;
  margin: 0 auto;
}

.featuresWhile h2 {
  color: #103356;
  font-weight: 600 !important;
  text-align: center !important;
  font-size: 5em !important;
}

.featuresSlider {
  text-align: center;
  margin-top: 30px;
}

.featuresSlider img {
  max-width: 100%;
  display: inline-block;
}

.section-frontEnd .afterTrip {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/home/happy_family.jpg")
    no-repeat left center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 500px;
  height: 90vh;
  margin-top: 30px !important;
  padding: 20px !important;
  overflow-x: hidden;
}

.react-parallax .react-parallax-bgimage {
  max-width: 10000px !important;
}

.afterTrip .divBox {
  padding: 20px 10px;
  display: inline-block;
  width: 100%;
  max-width: 800px;
  text-align: left;
  background: rgba(255, 255, 255, 0.72);
}

.afterTrip .divBox h3 {
  color: #1569ae;
  font-size: 4.5em;
  letter-spacing: 2px;
  font-weight: 600 !important;
}

.afterTrip .divBox p {
  font-size: 2em;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: lighter;
}

.howItWorks {
  transform: translateY(-35%);
}

.howItWorks .computers img {
  width: 100%;
  max-width: 720px;
}

.howItWorks .btnContainer {
  padding: 20px;
  background-color: #f9f9f9;
  text-align: center;
  margin-top: 40px;
}

.howItWorks .btnContainer .wordpressButton.howWorks {
  background-color: #bf24e9;
}

.feedbacks {
  margin-top: -50px !important;
}

.feedbacks h3 {
  text-align: center;
  font-size: 3em !important;
}

.feedbacks h3 + div {
  text-align: center;
  font-size: 1.9em;
  line-height: 2;
}

.message {
  font-size: 1.3em;
  font-weight: lighter;
  letter-spacing: 1px;
  font-style: italic;
}

.author .name {
  color: #4054b2;
  font-size: 14px;
  font-weight: 600;
}

.author .desig {
  color: #999;
}

.author img {
  width: 50px;
  border-radius: 50%;
}

.feedbacks .messages + .messages {
  margin-top: 50px;
}

.afterTripParallax {
  margin-top: 30px;
}

.securityParallax {
  margin-top: 50px;
}

.securityPoints {
  min-height: 500px;
  margin-top: 50px !important;
  color: #fff;
  text-align: center;
  padding: 20px !important;
}

.securityPoints h4 {
  margin-top: 20px !important;
  font-size: 2em !important;
  font-weight: 500 !important;
}

.securityPoints p {
  font-size: 1.3em !important;
  margin-top: 20px !important;
  letter-spacing: 0.5pt;
  display: inline-flex;
  text-align: left;
  width: 100%;
  max-width: 250px;
}

.ourTeams {
  margin-top: 50px !important;
}

.ourTeams .wordpressButton {
  background-color: #38b6ff;
}

.ourTeams h5 {
  font-size: 4em !important;
  font-weight: 600 !important;
}

.ourTeams p {
  margin-top: 20px !important;
  font-size: 2em !important;
  width: 100%;
  max-width: 360px;
  font-weight: lighter !important;
  line-height: normal;
}

.ourClients {
  background-color: #f9f9f9 !important;
  padding: 30px 0 !important;
  margin-top: 50px !important;
}

.ourClients .container {
  margin-top: 2em;
}

.ourClients [class^="col-"] .flexElem > div:nth-child(1) {
  flex: calc(199 / 66);
}

.ourClients [class^="col-"] .flexElem > div:nth-child(2) {
  flex: calc(305 / 69);
  margin-left: 40px;
  margin-right: 40px;
}

.ourClients [class^="col-"] .flexElem > div:nth-child(3) {
  flex: calc(196 / 89);
}

.ourClients [class^="col-"] img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.ourClients a {
  color: inherit !important;
  font-size: 1.8em !important;
  font-weight: lighter;
}

.ourClients > .text-center + .text-center {
  font-size: 3em !important;
}

.subFooter .col-md-12 > div {
  min-height: 300px;
  justify-content: center;
}

.subFooter .col-md-12 > div > div:first-child {
  font-size: 2.5em;
  margin-right: 20px;
  font-weight: lighter;
}

.subFooter.contact .col-md-12 > div > div:first-child {
  font-size: 2.5em;
  margin-right: 20px;
  font-weight: 600;
  color: #1569ae;
}

#tour_operator .subFooter.contact .col-md-12 > div > div:first-child {
  font-size: 3em;
  margin-right: 20px;
  font-weight: lighter;
  color: #252936;
  margin-bottom: 0;
}

.subFooter .col-md-12 > div .wordpressButton {
  background-color: #ffab26;
}

#how_it_works > .banner {
  position: relative;
  overflow: hidden;
}

#how_it_works > .banner:after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: rgba(255, 255, 255, 0.5); */
}

.wordpressHeading {
  color: #002d5e;
  font-size: 4em !important;
  font-weight: 600 !important;
  line-height: normal;
}

.wordpressHeading.subHeading {
  color: #002d5e;
  font-size: 2.5em !important;
  font-weight: normal !important;
}

.steps {
  margin-top: 50px !important;
}

.steps .container-fluid,
.benefits .container-fluid,
.moreThan .container-fluid {
  max-width: 1600px;
}

.steps .col-md-6 {
  margin-top: 50px !important;
}

.steps .col-md-6 > .flexElem > div:last-child {
  flex-basis: calc(60% - 20px);
}

.steps .col-md-6 img {
  width: 100%;
}

.steps .col-md-6 .step_heading,
.appCards .appCardHeading {
  color: #1569ae;
  font-size: 2em;
  font-weight: 600;
}

.steps .col-md-6 .step_details {
  font-size: 1.4em;
  color: #666;
  font-weight: 400;
  line-height: 1.5;
}

.youtube {
  margin-top: 100px !important;
}

.youtube .wordpressHeading,
.inspire .wordpressHeading {
  color: #1569ae;
  font-weight: 700 !important;
  font-size: 3.5em !important;
  line-height: normal;
}

.youtube .wordpressHeading + p,
.appCards .appCardHeading + p {
  font-size: 1.5em;
}

.subFooter.contact {
  background-color: #f9f9f9;
  padding: 20px 0 !important;
  margin-top: 50px !important;
}

.subFooter.contact .col-md-12 > div {
  min-height: auto;
}

.subFooter.contact .wordpressButton {
  background-color: #38b6ff !important;
}

.appCards {
  margin-top: 50px !important;
}

.how_to_order {
  margin-top: 50px !important;
  min-height: 500px;
  padding: 20px !important;
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/works/photo_gallery.png")
    no-repeat center !important;
  background-size: 100% !important;
  background-attachment: fixed !important;
  position: relative;
}

.how_to_order > div {
  position: relative;
  z-index: 1000;
}

.how_to_order:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}

.how_to_order .wordpressHeading {
  color: #1569ae !important;
}

.how_to_order .container p {
  font-size: 2em;
}

.inspire {
  margin-top: 50px !important;
}

.inspire img {
  border-radius: 20px;
  border: 1px solid #fff !important;
  box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.3);
}

.inspire.leaderGift img {
  border-radius: 0px;
  border: none !important;
  box-shadow: none;
}

.inspire .wordpressHeading {
  font-size: 2.8em !important;
}

.inspire p {
  font-size: 1.6em !important;
}

.benefits {
  margin-top: 100px !important;
}

.benefits .blockHeading {
  color: #1569ae;
  font-size: 1.9em;
  font-weight: 600;
  margin-top: 10px;
}

.benefits .blockHeading + p {
  margin-top: 10px;
  font-size: 1.3em;
}

.faqs {
  margin-top: 100px !important;
}

.faqs .col-md-8 {
  margin-top: 20px;
}

.faqs .faqList {
  border-bottom: 2px solid #eee;
}

.faqs .faqList .quest {
  padding: 20px 0;
  font-weight: lighter;
  font-size: 1.8em;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.faqs .faqList .quest > .iconArrow {
  font-size: 0.7em;
  margin-right: 6px;
  line-height: 0;
  transition: 0.2s all;
  margin-top: 11px;
}

.faqs .faqList .quest.open {
  color: #1569ae;
}

.faqs .faqList .quest.open .iconArrow {
  transform: rotate(-90deg);
}

.faqs .faqList .ans {
  padding-bottom: 20px;
  font-size: 1.35em;
  display: none;
  color: #666;
}

.faqs .wordpressHeading {
  color: #1569ae;
  font-size: 2.5em !important;
}

#tour_operator .phoneScreen > div:first-child {
  background-color: rgba(255, 255, 255, 0.85);
  color: #1569ae;
  padding: 10px;
  width: 100%;
  max-width: 390px;
  text-align: left;
}

#tour_operator .phoneScreen > div:first-child h1 {
  font-size: 2.4em;
  font-weight: 600 !important;
}

#tour_operator .banner {
  position: relative;
  overflow: hidden;
  padding: 50px 0 !important;
}

#tour_operator .banner .phoneScreen > div:last-child {
  margin-left: -44px;
}

#tour_operator .banner > .text-center {
  position: relative;
  z-index: 1000;
}

#tour_operator .banner .bgVideo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#tour_operator .banner .bgVideo video {
  height: auto;
}

.moreThan {
  margin-top: 50px !important;
}

.moreThan .container-fluid {
  margin-top: 30px !important;
}

.moreThan .wordpressHeading {
  margin-top: 30px !important;
  color: #1569ae;
  font-size: 3.5em !important;
}

.moreThan .wordpressHeading.subHeading {
  margin-top: 0px !important;
  font-size: 2.5em !important;
}

.moreThan [class^="col-"] > div {
  margin-top: 40px;
  min-height: 340px;
  padding: 0 25px;
}

.moreThan [class^="col-"] > div img {
  height: 70px;
}

.moreThan [class^="col-"] > div .blockHeading {
  font-size: 2.5em;
  font-weight: 600;
  margin-top: 10px;
}

.moreThan [class^="col-"] > div .blockHeading + p {
  font-size: 1.2em;
  margin-top: 5px;
}

.postTrip {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/operator/family.jpeg")
    no-repeat center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  padding: 20px 0 !important;
  min-height: 550px;
  overflow: hidden;
}

.postTrip .block {
  padding: 10px;
  background-color: rgb(255, 255, 255, 0.85);
}

.postTrip .block h3 {
  color: #1569ae;
  font-size: 3.3em;
  font-weight: 600;
}

.postTrip .block h3 + p {
  font-size: 2em;
  line-height: normal;
}

#tour_operator .inspire .wordpressButton {
  background-color: #1569ae;
}

#tour_operator .inspire .wordpressHeading {
  font-weight: normal !important;
}

#tour_operator .inspire .wordpressHeading.market {
  font-size: 3.3em !important;
  font-weight: 600 !important;
  color: #333;
}

.yourBrand .wordpressHeading > div {
  display: inline-flex;
  width: 100%;
  max-width: 600px;
  color: #1569ae;
  line-height: normal;
}

.yourBrand {
  margin-top: 100px !important;
}

.yourBrand .brandingImages {
  text-align: center;
  margin-top: 20px;
}

.yourBrand .brandingImages img {
  height: auto;
  max-height: 160px;
  margin: 20px 13px;
  max-width: 100%;
}

.yourBrand .brandingImages img:nth-child(1),
.yourBrand .brandingImages img:nth-child(2),
.yourBrand .brandingImages img:nth-child(3) {
  max-height: 230px;
  margin: 20px;
}

.leaderGift .wordpressHeading {
  font-size: 3.5em !important;
  line-height: 1 !important;
}

.leaderGift .wordpressHeading + p {
  margin-top: 30px;
}

.lighter {
  font-weight: lighter !important;
  font-size: 0.9em !important;
}
.logoSettings {
  width: 192px;
}
@media (min-width: 768px) {
  .advanceSearch .modal-dialog,
  .editOrderDetail .modal-dialog {
    width: auto;
    max-width: calc(100% - 30px);
    margin: 30px auto;
  }
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
  .navbar .navbar-brand {
    padding: 10px 15px;
  }
}

@media (min-width: 992px) {
  /* .container {
    max-width: 952px;
    width: 100%;
  } */
  .logoSettings {
    width: 280px;
  }
  .steps .col-md-6 > .flexElem > div:last-child {
    margin-left: 20px;
  }
  .benefits .wordpressHeading {
    font-size: 5em !important;
  }
  section.appCards,
  section.inspire {
    padding: 70px 0 !important;
  }
  .custom-search-input {
    width: 100%;
    max-width: 440px;
  }
  .fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 27px;
    opacity: 0;
    transform-origin: 0 0;
  }
  .fixed-plugin .dropdown.open .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transform-origin: 0 0;
  }
  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
  .fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
  }
  .fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -15px;
  }
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }
  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
  .fixed-plugin {
    top: 300px;
  }
}

.customTable.table tbody tr td {
  color: inherit !important;
}

.customLink:hover {
  color: inherit;
}

.customLink:hover {
  text-decoration: underline;
}

.flexResponsive,
.flexResponsiveSM {
  flex-wrap: wrap;
}

.flexEnd {
  align-items: flex-end;
}

.flexRev {
  flex-direction: row-reverse;
}

.featuresSlider .slick-prev,
.slick-next {
  font-size: 2.5em;
  z-index: 1000;
  color: #333 !important;
  line-height: 0;
}

/*
.featuresSlider .slick-prev{
  left: 0;
}

.featuresSlider .slick-next{
  right: 0;
} */

/* .container {
  max-width: 100%;
} */

@media (max-width: 991px) and (min-width: 601px) {
  .steps .col-md-6 img {
    max-width: 500px !important;
    transform: translateX(-20px);
    width: 107%;
  }
  .inspire.marketing .row > div {
    width: 50% !important;
  }
  .inspire.marketing .row {
    align-items: stretch;
  }
  .inspire.marketing .row > div:first-child img {
    height: calc(100% - 80px);
    object-fit: cover;
  }
  #tour_operator .inspire.leaderGift .wordpressHeading {
    font-size: 2em !important;
  }
  #tour_operator .inspire.leaderGift p {
    font-size: 1.4em !important;
  }
  .securityPoints p {
    font-size: 1.5em !important;
  }
  .section-frontEnd .inspire img {
    width: auto;
    max-width: 100%;
    height: 90%;
    object-fit: cover;
  }
  .section-frontEnd .inspire .alignCenter {
    align-items: stretch;
  }
}

@media (max-width: 991px) {
  .securityPoints img {
    width: 33.33333333333333333vw !important;
  }
  .section-frontEnd .afterTrip {
    height: 50vh;
    background-size: auto 63%;
  }
  .leaderGift .wordpressHeading span {
    font-size: 1.5em !important;
  }
  .wordpressButton {
    font-size: 20px !important;
  }
  #tour_operator {
    font-size: 11px;
  }
  #gtv_home {
    font-size: 11px;
  }
  .featuresWhile h2 {
    font-size: 4em !important;
  }
  .gtvIcons {
    flex-direction: row;
    gap: 20px;
  }
  .gtvIcons img {
    width: 275px;
  }
  .youtube .wordpressHeading {
    font-size: 3em !important;
  }
  .yourBrand .brandingImages img {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 90%;
  }
  #tour_operator .phoneScreen > div:first-child h1 {
    font-size: 2.5em !important;
  }
  .afterTrip .divBox h3 {
    font-size: 3.5em !important;
  }
  .afterTrip .divBox p {
    font-size: 1.5em !important;
  }
  .securityPoints .col-md-4 + .col-md-4 {
    margin-top: 40px;
  }
  .afterTrip .divBox {
    margin-right: 0;
  }
  .message {
    font-size: 1.5em;
  }
  .ourTeams img {
    margin-top: 20px !important;
  }
  .ourTeams p {
    display: inline-flex;
  }
  .how_to_order .wordpressHeading {
    font-size: 3.5em !important;
  }
  .bgVideo .videoContainer {
    max-height: none;
    height: 350px;
  }
  .bgVideo .videoContainer video {
    height: 100%;
    width: auto;
  }
  .bgVideo .inlineFlexElem {
    flex-direction: column-reverse;
  }
  .moreThan [class^="col-"] > div .blockHeading + p {
    font-size: 1.3em;
  }
  .flexResponsive .custom-search-input {
    margin-top: 15px;
  }
  .moreThan [class^="col-"] > div {
    min-height: auto;
  }
  #tour_operator .banner .bgVideo video {
    width: auto;
    max-width: none;
    height: 100%;
    min-height: 870px;
  }
  .orderTabs .react-tabs > ul {
    flex-direction: column;
    align-items: center;
  }
  .orderTabs .react-tabs > ul > li {
    margin-top: 10px;
  }
  .orderTopNav {
    align-items: center;
    justify-content: center;
  }
  .orderTopNav > .navButtons {
    margin-top: 15px;
  }
  .marginLeft991 {
    margin-left: 0px !important;
    margin-top: 10px !important;
  }
  .flexResponsive {
    flex-direction: column;
    align-items: center;
  }
  .flexResponsive > div {
    width: 100% !important;
  }
  .fixed-plugin .dropdown-menu {
    right: 60px;
    width: 220px;
  }
  .fixed-plugin .dropdown-menu li {
    width: 50%;
  }
  .fixed-plugin li.adjustments-line,
  .fixed-plugin li.header-title,
  .fixed-plugin li.button-container {
    width: 100%;
    height: 55px;
    min-height: inherit;
  }
  .fixed-plugin .adjustments-line .switch {
    float: right;
    margin: 0 0px;
  }
  .fixed-plugin li.header-title {
    height: 40px;
  }
  .fixed-plugin .dropdown .dropdown-menu {
    top: -170px;
  }
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}

.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}

.btn-social.btn-round {
  padding: 9px 10px;
}

.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}

.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus,
.btn-facebook:active,
.btn-facebook.active,
.open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}

.btn-facebook:disabled,
.btn-facebook[disabled],
.btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}

.btn-facebook.btn-fill {
  color: #ffffff;
  background-color: #3b5998;
  opacity: 0.9;
}

.btn-facebook.btn-fill:hover,
.btn-facebook.btn-fill:focus,
.btn-facebook.btn-fill:active,
.btn-facebook.btn-fill.active,
.open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: #ffffff;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}

.btn-twitter:hover,
.btn-twitter:focus,
.btn-twitter:active,
.btn-twitter.active,
.open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}

.btn-twitter:disabled,
.btn-twitter[disabled],
.btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}

.btn-twitter.btn-fill {
  color: #ffffff;
  background-color: #55acee;
  opacity: 0.9;
}

.btn-twitter.btn-fill:hover,
.btn-twitter.btn-fill:focus,
.btn-twitter.btn-fill:active,
.btn-twitter.btn-fill.active,
.open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 992px) {
  .typo-line {
    padding-left: 140px;
    margin-bottom: 40px;
    position: relative;
  }
  .typo-line .category {
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    position: absolute;
  }
}

.all-icons [class*="pe-"] {
  font-size: 40px;
}

.all-icons input {
  border: 0;
}

.all-icons .font-icon-detail {
  text-align: center;
  padding: 45px 0px 30px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  margin: 15px 0;
}

.overlayModify .css-a3xia2,
.overlayModify .css-1028e8w,
.overlayModify .css-ta3lg2 {
  background-color: rgba(120, 130, 255, 0.95);
}

.overlayModify .css-42igfv {
  color: rgba(120, 130, 255, 1);
}

.overlayModify > div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.9) */
}

.all-icons .font-icon-detail input {
  margin: 25px auto 0;
  width: 100%;
  text-align: center;
  display: block;
  color: #aaa;
  font-size: 13px;
}

/* LofingOverlay in photo gallery */

.overlayModify .css-a3xia2,
.overlayModify .css-1028e8w,
.overlayModify .css-ta3lg2 {
  background-color: rgba(120, 130, 255, 0.95);
}

.overlayModify .css-42igfv {
  color: rgba(120, 130, 255, 1);
}

.overlayModify > div {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.9) */
}

#map {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
}

.places-buttons .btn {
  margin-bottom: 30px;
}

.sidebar .nav > li.active-pro {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.sidebar .nav > li.active-pro a {
  background: rgba(255, 255, 255, 0.14);
  opacity: 1;
  color: #ffffff;
}

.table-upgrade td:nth-child(2),
.table-upgrade td:nth-child(3) {
  text-align: center;
}

.ReactTable .-pagination .-btn {
  height: auto !important;
}

.modal-open .modal {
  display: block;
}

.action-center {
  text-align: center !important;
}

.justifyStart {
  justify-content: start !important;
}

.mozaixImageOnQA {
  display: flex;
  justify-content: center;
}

.action-button-size {
  font-size: 17px;
  margin-left: 10px;
  border: none !important;
  background-color: transparent !important;
  color: #444;
}

.outOfTableButton {
  font-size: 15px !important;
  line-height: 10px;
  color: #1dc7ea !important;
  margin-top: 12px;
}

.custom_btn {
  font-size: 15px !important;
  line-height: 10px;
  margin-top: 12px;
}

.marginFromTopPopUp {
  margin-top: 10px;
}

.myTable tr td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.react-confirm-alert-body {
  max-width: 480px;
  width: 100%;
}
.react-confirm-alert-body-ship {
  max-width: 720px;
  width: 100%;
}

.customHalf > .col-md-6 {
  width: calc(50% - 28px);
  padding-top: 0 !important;
}

.card.innerCard .table-striped > tbody > tr:nth-of-type(odd) {
  background: rgba(255, 255, 255, 0.7);
}

.card.innerCard .table-striped > thead > tr > th {
  padding-top: 13px;
  padding-bottom: 13px;
  color: #333;
}

.card.innerCard .table-striped > thead > tr {
  background: #aac9f8;
}

.card.innerCard .table > tbody > tr > td {
  padding-top: 6px;
  padding-bottom: 6px;
}

.lessSpace.customHalf > .col-md-6 {
  width: calc(50% - 10px);
}

.imagePreview {
  height: auto;
  width: 70px;
  margin: 10px 0;
  border: 1px solid #eee;
}

.imageUploadWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.buttonHide {
  display: none;
}

.buttonShow {
  display: block;
}

.login_form_container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  /* background: #f8f8f8; */
  padding: 50px 10px;
}

.login_form_container h1 {
  font-size: 30px;
  margin: 0 0 35px 0;
  font-weight: 500;
}
.logo_container {
  margin-bottom: 35px;
}

.main_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  /* height: 100%;
    min-height: 650px; */
}

.login_form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login_form input {
  border-color: #dfdfdf;
  line-height: 2.3 !important;
  height: auto;
  background: #fff;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 2px;
  margin-bottom: 20px;
}

.login_form input + .help-block {
  margin-top: -13px;
}

.login_form .password_ctrl {
  background-image: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/password.png");
  background-repeat: no-repeat;
  background-position: 95% center;
}

.login_form .email_ctrl {
  background-image: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/user.png");
  background-repeat: no-repeat;
  background-position: 95% center;
}

.loginLink {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/user.png")
    no-repeat left center;
  padding-left: 22px;
  height: 25px;
}

.login_form ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7b7b7b;
  font-size: 15px;
}

.login_form ::-moz-placeholder {
  /* Firefox 19+ */
  color: #7b7b7b;
  font-size: 15px;
}

.login_form :-ms-input-placeholder {
  /* IE 10+ */
  color: #7b7b7b;
  font-size: 15px;
}

.login_form :-moz-placeholder {
  /* Firefox 18- */
  color: #7b7b7b;
  font-size: 15px;
}

.logoImageLogin {
  width: 140px;
}

.select_height_adjust > div[class^="css-"] > div[class^="css-"] {
  min-height: 40px;
}

.css-kj6f9i-menu {
  z-index: 16777271;
  /*text-transform: lowercase;*/
}

.rt-td.showSelect {
  overflow: visible !important;
  position: relative !important;
}

.css-kj6f9i-menu *::first-letter {
  /*text-transform: uppercase;*/
}

.btn_link {
  border: none;
  background: none;
  padding: 0;
  outline: none;
}

.customBorder {
  border: 1px solid hsl(0, 0%, 80%);
}

.customBorder:focus {
  border: 2px solid #2782fc;
}

.btn_link.button_disabled {
  color: #999 !important;
  border: none !important;
  background: none !important;
  cursor: not-allowed !important;
  outline: none;
  padding: 0;
}

.srv-validation-message {
  color: tomato;
}

.requiredClass {
  color: tomato;
}

.customButton {
  margin-top: 0px;
  line-height: 20px;
}

table.actions_table {
  margin-bottom: 0;
}

table.actions_table tr:first-child td {
  border: none;
}

.iconNavSidebar > a {
  display: flex !important;
  align-items: center;
}

.react-confirm-alert-overlay {
  z-index: 1060;
}

.iconNavSidebar > a > img {
  width: auto;
  height: 20px;
  margin-right: 10px;
}

.sidebar:before,
body > .navbar-collapse:before,
.sidebar:after,
body > .navbar-collapse:after {
  background: #2c3356 !important;
}

.dollar_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/dollar_icon_sm.png")
      no-repeat 10px center,
    #fff;
  padding-left: 30px;
}

.calender_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/calender.png")
      no-repeat 10px center,
    #fff;
  padding-left: 40px;
}

.search_icon {
  background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/search_icon.png")
      no-repeat 10px center,
    #fff;
  padding-left: 40px;
}

.fileContainer .deleteImage {
  background: #322e58 !important;
}

.alignFlexButtons {
  justify-content: center;
}

.alignFlexButtons .btn {
  font-size: 18px;
}

.alignFlexButtons .btn-danger {
  background: red;
  border: 1px solid rgb(255, 255, 255);
}

.alignFlexButtons .btn-danger:hover {
  background: #fff;
  border: 1px solid red;
  color: red;
}

.alignFlexButtons .btn-success {
  background: #049f0c;
  border: 1px solid rgb(255, 255, 255);
}

.alignFlexButtons .btn-success:hover {
  background: #fff;
  border: 1px solid #049f0c;
  color: #049f0c;
}

.button_disabled {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.mb-20-test {
  margin-bottom: 20px !important;
}

@keyframes show {
  from {
    max-height: 0px;
    opacity: 0;
  }
  to {
    max-height: 200px;
    opacity: 1;
  }
}

.sideNavToggle,
.sideNavClose {
  display: none;
}

.sidebar .nav li > span {
  display: inline-flex;
}

.sidebar .nav p {
  white-space: nowrap;
}

.includePerformance p {
  color: #545454;
  font-size: 1.3em !important;
}

@media (min-width: 991px) {
  .includePerformance {
    padding: 0 40px;
  }
  .bannerContent .itsLike + p {
    max-width: 470px;
  }
  .includePerformance .wordpressHeading {
    font-size: 3em !important;
    color: #1569ae !important;
  }
  .videoUploaderSection > div:first-child {
    margin-right: 10px;
  }
  .videoUploaderSection > div:last-child {
    margin-left: 10px;
  }
  .sidebar {
    position: fixed;
    z-index: 100;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 50px;
    overflow: hidden;
  }
  .sidebar.showSideNav {
    width: auto;
    animation: showNav 0.1s ease;
  }
  .sidebar.showSideNav + .main-panel {
    transition: 0.1s all ease;
  }
  .main-panel {
    width: calc(100% - 50px);
  }
  .sidebar .sidebar-wrapper {
    width: auto !important;
    overflow: hidden;
    margin-top: 70px;
  }
  .sidebar.showSideNav .sidebar-wrapper {
    overflow-y: auto;
  }
  .sideNavToggle {
    float: left;
    margin: 22px 13px 0;
    cursor: pointer;
    display: block;
  }
  .sideNavClose {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: block;
  }
}

@media (min-width: 1366px) {
  html,
  body {
    font-size: 16px;
  }
  .container {
    max-width: 1170px;
    width: 100%;
  }
  .commentUserDate + div {
    font-size: 20px;
  }
  .logo-img img {
    height: 40px;
    width: auto;
  }
  .logo.navbar-brand {
    margin-bottom: 20px;
  }
  .headerLogin {
    margin-top: 25px;
  }
  .navbar {
    font-size: 20px;
  }
  .dropdown-menu {
    font-size: 16px;
  }
  .sidebar .nav li.openSubMenu > .subnav > li a {
    font-size: 16px;
  }
  p {
    font-size: 17px;
  }
  .table.orderStatusContainer thead tr th {
    font-size: 17px;
  }
  .table.ttkOrderStatusContainer thead tr th {
    font-size: 17px;
  }
  .table > tbody > tr > td {
    font-size: 17px;
  }
  .orderDetailsHeader {
    font-size: 21px;
  }
  .orderDetailsBox .orderTable tr td {
    font-size: 17px;
  }
  .orderTable.table-striped > thead tr th,
  .orderTable.table-striped > tbody tr td {
    font-size: 17px;
  }
  .orderDetailsAction {
    font-size: 17px;
  }
  .orderDetailsAction.small_icon {
    font-size: 17px;
  }
  .card label {
    font-size: 17px;
    line-height: normal;
  }
  .sidebar .nav p {
    font-size: 15px;
  }
  .imgIconMarRight {
    margin-right: 7px;
  }
  .btn {
    font-size: 17px;
  }
  .form-control {
    font-size: 17px;
  }
  .card.innerCard .header .title.headerTitle {
    font-size: 21px;
  }
  .appCardActions > div .appCardBtn {
    font-size: 19px;
  }
  .secHeading {
    font-size: 23px;
  }
  .orderSettingText p.text-muted {
    font-size: 17px;
  }
  #docForm p.hello {
    font-size: 19px;
  }
  .footer .copyright {
    font-size: 17px;
  }
}
@media (min-width: 1280px) {
  #header .containerHeader,
  #footer .containerFooter {
    max-width: 1280px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*handle small screen*/

@media (min-width: 1600px) {
  html,
  body {
    font-size: 20px;
  }
  /* .container, */
  #header .containerHeader,
  #footer .containerFooter {
    max-width: 1536px;
    width: 100%;
  }

  .commentUserDate + div {
    font-size: 22px;
  }
  .logo-img img {
    height: 40px;
    width: auto;
  }
  .logo.navbar-brand {
    margin-bottom: 20px;
  }
  .headerLogin {
    margin-top: 25px;
  }
  .navbar {
    font-size: 20px;
  }
  .dropdown-menu {
    font-size: 16px;
  }
  .sidebar .nav li.openSubMenu > .subnav > li a {
    font-size: 18px;
  }
  p {
    font-size: 19px;
  }
  .table.orderStatusContainer thead tr th {
    font-size: 19px;
  }
  .table.ttkOrderStatusContainer thead tr th {
    font-size: 19px;
  }
  .table > tbody > tr > td {
    font-size: 19px;
  }
  .modal-lg .table > tbody > tr > td {
    font-size: 16px;
  }
  .orderDetailsHeader {
    font-size: 23px;
  }
  .orderDetailsBox .orderTable tr td {
    font-size: 19px;
  }
  .orderTable.table-striped > thead tr th,
  .orderTable.table-striped > tbody tr td {
    font-size: 19px;
  }
  .orderDetailsAction {
    font-size: 19px;
  }
  .orderDetailsAction.small_icon {
    font-size: 19px;
  }
  .card label {
    font-size: 19px;
    line-height: normal;
  }
  .sidebar .nav p {
    font-size: 18px;
  }
  .imgIconMarRight {
    margin-right: 7px;
  }
  .btn {
    font-size: 19px;
  }
  .form-control {
    font-size: 19px;
  }
  .card.innerCard .header .title.headerTitle {
    font-size: 23px;
  }
  .appCardActions > div .appCardBtn {
    font-size: 21px;
  }
  .secHeading {
    font-size: 25px;
  }
  .orderSettingText p.text-muted {
    font-size: 19px;
  }
  #docForm p.hello {
    font-size: 21px;
  }
  .footer .copyright {
    font-size: 19px;
  }
}

/*handle large screen*/

@media (min-width: 1921px) {
  html,
  body {
    font-size: 24px;
  }
  .container {
    max-width: 1780px;
    width: 100%;
  }
  .commentUserDate + div {
    font-size: 24px;
  }
  .logo-img img {
    height: 50px;
    width: auto;
  }
  .logo.navbar-brand {
    margin-bottom: 24px;
  }
  .headerLogin {
    margin-top: 25px;
  }
  .navbar {
    font-size: 25px;
  }
  .dropdown-menu {
    font-size: 23px;
  }
  .sidebar .nav li.openSubMenu > .subnav > li a {
    font-size: 22px;
  }
  p {
    font-size: 23px;
  }
  .table.orderStatusContainer thead tr th {
    font-size: 23px;
  }
  .table.ttkOrderStatusContainer thead tr th {
    font-size: 23px;
  }
  .table > tbody > tr > td {
    font-size: 23px;
  }

  .orderDetailsHeader {
    font-size: 27px;
  }
  .orderDetailsBox .orderTable tr td {
    font-size: 23px;
  }
  .orderTable.table-striped > thead tr th,
  .orderTable.table-striped > tbody tr td {
    font-size: 23px;
  }
  .orderDetailsAction {
    font-size: 23px;
  }
  .orderDetailsAction.small_icon {
    font-size: 22px;
  }
  .card label {
    font-size: 23px;
    line-height: normal;
  }
  .sidebar .nav p {
    font-size: 20px;
  }
  .imgIconMarRight {
    margin-right: 7px;
  }
  .btn {
    font-size: 22px;
  }
  .form-control {
    font-size: 23px;
  }
  .card.innerCard .header .title.headerTitle {
    font-size: 27px;
  }
  .appCardActions > div .appCardBtn {
    font-size: 25px;
  }
  .secHeading {
    font-size: 29px;
  }
  .orderSettingText p.text-muted {
    font-size: 23px;
  }
  #docForm p.hello {
    font-size: 25px;
  }
  .footer .copyright {
    font-size: 23px;
  }
}

@media (max-width: 1366px) {
  .steps .col-md-6 .step_details {
    font-size: 1.2em;
  }
  .steps .col-md-6 .step_heading {
    font-size: 1.8em;
  }
}

.inspire.stregthen .flexElem {
  flex-direction: row-reverse;
}

.yourBrand .wordpressHeading span {
  width: 100%;
  display: block;
  font-size: 0.55em;
  font-weight: bold;
}

.yourBrand .wordpressHeading > div {
  width: 100%;
  max-width: 600px;
  text-align: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 1.1em !important;
}

@media (max-width: 991px) {
  .yourBrand .wordpressHeading > div {
    max-width: 400px;
  }
  #main-panel {
    width: 100% !important;
  }
  div#sidebar,
  #sidebar div.sidebar-wrapper {
    width: auto;
  }
  ul.nav.pull-right {
    float: none !important;
  }
  .steps .col-md-6 img {
    max-width: 300px;
  }
  .steps .col-md-6 > .flexElem > div:first-child {
    padding: 0 20px;
  }
  .inspire .wordpressHeading {
    font-size: 1.5em !important;
  }
  .inspire .wordpressHeading + p {
    font-size: 1.05em !important;
  }
  #tour_operator .inspire .wordpressHeading {
    font-size: 2.5em !important;
  }
  #tour_operator .inspire .wordpressHeading + p {
    font-size: 1.7em !important;
  }
  #tour_operator .inspire.marketing .wordpressHeading + p {
    font-size: 1.5em !important;
  }
  .section-frontEnd .benefits [class^="col-"] > div {
    padding: 0 !important;
  }
  #how_it_works .ourClients a {
    font-size: 1.5em !important;
  }
  #how_it_works .ourClients > .text-center + .text-center {
    font-size: 2em !important;
  }
  #tour_operator .phoneScreen > div:first-child {
    max-width: 250px;
  }
  .section-frontEnd .moreThan [class^="col-"] > div {
    padding: 0;
  }
  .moreThan [class^="col-"] > div .blockHeading {
    font-size: 1.65em;
  }
  .section-frontEnd .postTrip {
    background-size: auto 80% !important;
    background-position: bottom center !important;
    min-height: 500px !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
  .postTrip .block h3 {
    font-size: 2.5em !important;
  }
  .postTrip .block h3 + p {
    font-size: 1.7em;
  }
  .yourBrand .brandingImages img {
    height: 15vw;
  }
  .yourBrand .brandingImages img + img {
    margin-left: 20px !important;
  }
  .yourBrand .brandingImages img:last-child {
    display: none !important;
  }
}

.inspire.leaderGift .flexElem {
  flex-direction: row-reverse;
}

.inspire.leaderGift .wordpressHeading span {
  display: block;
}

.smallText {
  margin-left: 10px !important;
  margin-top: 10px !important;
  font-size: 11px !important;
}

.folderBtn {
  background: #ff00ff;
  border-radius: 0px;
  box-shadow: 2px 3px #95bcf2;
  transition: 0.3s all;
  border: none;
  text-decoration: none;
  line-height: normal;
  outline: none;
  max-width: 100%;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  /* font-family: "Poppins", sans-serif; */
  text-transform: uppercase;
  color: #fff;
  padding: 12px 26px;
  letter-spacing: 0.5pt;
}

.outerParent {
  display: flex;
  flex-direction: column;
  /* max-width: 50%; */
}

.agencyFolder {
  margin-bottom: 10px !important;
}

.parent {
  /* background-color: rgb(63, 58, 58); */
  background-color: #eeeeee;
  margin-bottom: 10px !important;
}

.parentAgency {
  /* background-color: rgb(63, 58, 58); */
  background-color: #eeeeee;
  margin-bottom: 10px !important;
}

.parent li .MuiTreeItem-content {
  padding: 8px 12px !important;
}

.parent ul.MuiTreeItem-group {
  background: #fff;
  margin-left: 0 !important;
  padding-left: 15px;
}

.parentAdmin ul.MuiTreeItem-group {
  background: #fff;
  margin-left: 0 !important;
  padding-left: 0px;
}

.parent ul .MuiCollapse-vertical {
}

/* .parent li[aria-expanded="false"] {
    background: url(./folder.png) no-repeat top 10px right 12px / 24px;
}

.parent li[aria-expanded="true"] {
    background: url(./folderOpen.png) no-repeat top 10px right 12px / 24px;
} */

.parent li .Mui-expanded.Mui-selected {
  color: #fff;
  background: #2b78e4;
}

.selecteFolder {
  display: flex;
  flex-direction: column;
  padding: 8px 12px !important;
  color: #fff;
  background: #2b78e4 url(./folderOpen.png) no-repeat top 10px right 12px / 24px;
}

.editedPhotoGallery {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 30px;
  text-align: center;
  margin-top: 50px;
}

.uploadedImageSize {
  min-height: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  position: relative;
}

.photoGallery {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.photoGallery .galleryId {
  display: inline-flex;
  padding: 3px 7px;
  background: tomato;
  color: #fff;
  border-radius: 24px;
}

.displayInline {
  display: flex !important;
  align-items: center;
}

.uploadedImageSize .delete_img,
.moving .uploadedImageSize .delete_img {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  z-index: 100;
}

@media (max-width: 768px) {
  .pagesLink {
    padding-left: 0px;
  }
  .outerParent {
    max-width: 100%;
  }
  .securityPoints h4 {
    font-size: 3em !important;
    font-weight: 600 !important;
  }
  .ourTeams {
    position: relative;
  }
  .ourTeams [class^="col-"] {
    position: static;
  }
  .ourTeams [class^="col-"] .mt-lg {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
  }
  .fullVideo {
    grid-template-columns: auto;
  }
  .bannerContent .inlineGridElem {
    align-items: center;
    gap: 10px;
    padding: 0 10px;
  }
  .bannerContent .inlineGridElem > div:nth-child(1) img {
    width: auto;
    height: 360px;
    max-width: 10000;
  }
  .bannerContent .inlineGridElem > div:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 3;
    padding-right: 10px;
  }
  .bannerContent .inlineGridElem > div:nth-child(2) img {
    width: 100px;
  }
  .bannerContent .inlineGridElem > div:nth-child(2) br {
    display: none;
  }
  .section-frontEnd .bannerContent .inlineGridElem > div:nth-child(3) {
    grid-column-start: 1 !important;
    grid-column-end: 6 !important;
  }
  .bannerContent .inlineGridElem > div:nth-child(3) .mt-xxlg {
    margin-top: 10px !important;
  }
  .section-frontEnd .bannerContent .inlineGridElem {
    width: 100% !important;
    max-width: 500px !important;
  }
  .section-frontEnd .bannerContent {
    transform: translateY(-63%);
  }
  .securityPoints p {
    text-align: inherit;
  }
  .steps .wordpressHeading {
    font-size: 2.9em !important;
  }
  .steps .wordpressHeading.subHeading {
    font-size: 1.7em !important;
  }
  .steps .col-md-6 img {
    max-width: 260px;
    transform: translateX(-20px);
  }
  .steps .col-md-6 .step_heading {
    font-size: 1.7em;
  }
  .steps .col-md-6 .step_details {
    font-size: 1.1em !important;
  }
  .youtube {
    margin-top: 30px !important;
  }
  .youtube .row > .col-md-6:first-child {
    padding: 0 50px;
  }
  .youtube .wordpressHeading,
  .appCards .wordpressHeading {
    font-size: 2.3em !important;
  }
  .youtube .wordpressHeading + p,
  .appCards .wordpressHeading.subHeading,
  .appCards .appCardHeading + p,
  .how_to_order .container p,
  .inspire p {
    font-size: 1.3em !important;
  }
  .appCardHeading {
    text-align: center;
    line-height: normal;
  }
  .how_to_order .wordpressHeading {
    font-size: 2em !important;
  }
  .how_to_order .wordpressHeading br {
    display: none;
  }
  .how_to_order .container p a {
    color: #3366ff;
  }
  .how_to_order {
    background-size: auto 100% !important;
  }
  .inspire .wordpressHeading,
  .benefits .wordpressHeading {
    font-size: 2em !important;
  }
  .benefits .blockHeading {
    text-align: center;
    font-size: 1.5em;
  }
  .benefits .blockHeading + p {
    font-size: 1.1em;
  }
  .faqs .wordpressHeading {
    font-size: 2.1em !important;
  }
  section.faqs {
    margin-top: 50px !important;
  }
  .faqs .col-md-8 {
    margin-top: 0;
  }
  .moreThan .wordpressHeading {
    font-size: 3em !important;
  }
  .moreThan .wordpressHeading.subHeading {
    display: none !important;
  }
  .moreThan p {
    text-align: left;
  }
  .moreThan [class^="col-"] + [class^="col-"] > div {
    margin-top: 40px;
  }
  .postTrip .block h3 {
    font-size: 2.2em;
  }
  .postTrip .block h3 + p {
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0.5pt;
  }
  #tour_operator .inspire .wordpressHeading {
    font-size: 2.3em !important;
  }
  #tour_operator .inspire .wordpressHeading + p {
    font-size: 1.65em !important;
  }
  #tour_operator .inspire.stregthen .wordpressHeading {
    /* font-size: 3.1em !important; */
  }
  #tour_operator .inspire .wordpressHeading.market {
    font-size: 2em !important;
  }
  #tour_operator .inspire .wordpressHeading.market + p + .mt-lg {
    text-align: center;
    margin-bottom: 20px;
  }
  .yourBrand .wordpressHeading > div {
    font-size: 0.65em;
    padding: 0 20px;
  }
  #tour_operator .inspire.leaderGift .wordpressHeading {
    text-align: left;
    width: 100%;
    margin: 0 auto;
  }
  #tour_operator .inspire.leaderGift p {
    margin-top: 15px;
  }
  .bannerContent .itsLike + p {
    font-size: 16px;
  }
  .bannerContent .wordpressButton.getInTouch {
    margin-top: 1em;
  }
  .login_form_container {
    padding-top: 20px;
  }
}

.slick-arrow {
  height: auto !important;
  width: auto !important;
}

.slick-arrow:before {
  display: none !important;
}

@media (max-width: 600px) {
  .section-frontEnd .inspire img {
    width: 100%;
    max-width: 300px;
  }
  .securityPoints img {
    width: 100%;
    max-width: 130px !important;
  }
  .gtvIcons img {
    width: 40%;
  }
  #tour_operator .inspire.leaderGift .wordpressHeading {
    font-size: 3em !important;
  }
  .leaderGift .wordpressHeading span {
    font-size: 0.9em !important;
  }
  .subFooter .col-md-12 > div > div:first-child {
    margin-right: 0;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .afterTrip .divBox h3 {
    font-size: 2.5em !important;
  }
  .feedbacks h3 + div {
    line-height: normal;
  }
  .section-frontEnd .afterTrip {
    background-size: auto 52%;
    height: 40vh;
  }
  .flexResponsiveSM {
    flex-direction: column;
    align-items: center;
  }
  .flexResponsiveSM > div {
    width: 100% !important;
  }
  #tour_operator .inspire .includePerformance .wordpressHeading {
    /* font-size: 2.5em !important; */
    text-align: center;
  }
  .yourBrand .brandingImages img {
    height: auto;
  }
  .yourBrand br {
    display: none;
  }
  .postTrip {
    background: url("https://gtvoriginalimages.s3.amazonaws.com/static/img/operator/family.jpeg")
      no-repeat center !important;
    background-size: auto 70% !important;
    background-attachment: fixed !important;
    padding: 20px 0 !important;
    min-height: 350px;
    overflow: hidden;
  }
  .slick-arrow {
    display: none !important;
  }
  .bannerContent .gtvLogo + br + h1 {
    font-size: 1.7em;
  }
  .bannerContent .inlineGridElem > div:nth-child(2) img {
    width: 90px;
  }
  .section-frontEnd .bannerContent {
    transform: translateY(calc(-70% + 7vw));
  }
  .securityPoints img {
    width: 100%;
    max-width: 100px !important;
  }
  .ourClients a {
    font-size: 1.3em !important;
    width: 100%;
    max-width: 200px;
    line-height: 1.2;
    display: inline-block;
  }
  .steps .col-md-6 .step_heading {
    font-size: 1.5em !important;
  }
  .ourClients > .text-center + .text-center {
    font-size: 2em !important;
    width: 100%;
    max-width: 200px;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    line-height: 1.2;
  }
  .steps .col-md-6 .step_heading {
    margin-top: 0px;
    text-align: center;
  }
  .youtube .wordpressHeading {
    font-size: 1.5em !important;
  }
  .youtube .wordpressHeading + p {
    font-size: 1em !important;
  }
  .benefits .col-md-4 + .col-md-4 {
    margin-top: 3.5em;
  }
  .ourClients [class^="col-"] .flexElem > div:nth-child(2) {
    flex: calc(305 / 69);
    margin-left: 30px;
    margin-right: 30px;
  }
  .subFooter .col-md-12 > div > div:first-child {
    line-height: normal;
    font-size: 1.5em;
  }
  .steps .col-md-6 > .flexElem,
  .section-frontEnd .inspire .row.flexElem,
  .section-frontEnd #tour_operator .banner .inlineFlexElem {
    flex-direction: column !important;
  }
  .section-frontEnd .inspire .row.flexElem [class^="col-"],
  .section-frontEnd .benefits [class^="col-"],
  .section-frontEnd .moreThan [class^="col-"] {
    width: 100%;
  }
  #tour_operator .phoneScreen > div:first-child {
    max-width: 100%;
    text-align: center;
  }
  .section-frontEnd .inspire .row.flexElem [class^="col-"]:first-child {
    text-align: center;
  }
  #tour_operator .banner .phoneScreen > div:last-child {
    margin-left: 0;
  }
  #tour_operator .subFooter.contact .col-md-12 > div > div:first-child {
    margin-right: 0;
    font-weight: 400;
    margin-bottom: 10px;
  }
  #tour_operator .subFooter.contact .flexElem {
    flex-direction: column;
  }
  #how_it_works .ourClients a {
    font-size: 1.2em !important;
  }
}

@media (min-width: 480px) {
  .ytWraper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
  }
  .ytWraper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .section-frontEnd .bannerContent {
    transform: translateY(calc(-65% + 3vw));
  }
  .steps .col-md-6 .step_details {
    padding: 5px 25px;
  }
  .ourClients a,
  .ourClients > .text-center + .text-center {
    width: 100%;
    max-width: 100%;
  }
  .ourClients > .text-center + .text-center {
    margin-top: 0px;
  }
  #how_it_works .ourClients,
  #tour_operator .ourClients {
    font-size: 12px;
  }
}

@keyframes showNav {
  from {
    max-width: 50px;
  }
  to {
    max-width: 500px;
  }
}

.p-10 {
  padding: 10px;
}

.align-items-center {
  align-items: center;
}

.ship-status-table tr td:nth-child(2) {
  background-color: whiteSmoke;
}

.my-20 {
  margin: 20px 0;
}

.mx-20 {
  margin: 0 20px;
}
.mx-auto {
  margin: 0 auto !important;
}

.mr-20 {
  margin-right: 20px;
}

.m-0 {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto;
}

.box {
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.14), -1px 0px 5px rgba(0, 0, 0, 0.14);
}

.box-primary {
  border-top: 3px solid #596a91;
}

.box-info {
  border-top: 3px solid #596a91;
}

.box-danger {
  border-top: 3px solid salmon;
}

.box-success {
  border-top: 3px solid #4dbd74;
}

.d-inline-block {
  display: inline-block;
}

.w-100 {
  width: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.table-striped-custom > tbody > tr:nth-of-type(2n + 1) {
  background-color: #eee;
}

.table-striped-custom td {
  padding: 2px 5px !important;
}

.d-inline-block {
  display: inline-block;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.6);
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.6);
}

.text-black {
  color: #333 !important;
}
.text-grey {
  color: #444 !important;
  cursor: not-allowed;
}

.topNav li a:hover {
  background-color: #2f4576;
  transform: scale(1.03);
}

@keyframes back {
  from {
    transform: translateX(25%) rotate(90deg);
  }
  to {
    transform: translateX(-25%) rotate(90deg);
  }
}

.docLoader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.notCard {
  border: none;
  box-shadow: none;
}

.notCard .content {
  border: none;
  padding: 0 !important;
}

.btn-info:disabled:hover {
  background-color: #2f4576;
}

.barcodePrint {
  position: absolute;
  top: 67%;
  right: calc(110pt - 2vw);
}

.orangeText,
.orangeText * {
  color: darkorange !important;
}

.semibold {
  font-weight: 500 !important;
  color: inherit !important;
}

.headingPrint {
  font-size: 18pt !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  color: white !important;
  background-color: #666 !important;
  text-align: center !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.headingPrint.heading1 {
  font-size: 20pt !important;
  background-color: #fff !important;
  color: #666 !important;
}

.printTable {
  /* table-layout: fixed !important; */
  width: 100% !important;
}

.printTable tr td,
.printTable tr th {
  padding: 3pt 5pt !important;
}

/* .printTable tr td:first-child{
  width: 40% !important;
}

.printTable tr td:last-child{
  width: 60% !important;
} */

.printTable tr:nth-child(even) {
  background: #eee !important;
}

@media print {
  @page {
    /* size: letter; */
    /* width: 70%;
        max-width: 70%;
        height: 80%;
        max-width: 70%; */
  }
  /* .labelPrint {
        max-height: 8in;
        margin-left: 20px;
        justify-content: center;
    } */
  .abc111 {
    /* page-break-inside: avoid;
        page-break-after: auto;
        page-break-before: avoid; */
    width: 4in;
    height: 6in;
    margin: 0.2 auto;
  }
  .abc111 img {
    /* page-break-inside: avoid;
        page-break-after: auto;
        page-break-before: avoid; */
    max-width: 4in;
    max-height: 6in;
    height: 100%;
    width: 100%;
    margin: 3px;
  }
  .barcodePrint {
    top: 65.9%;
    right: calc(70pt - 5.5vw);
    width: auto;
  }
  .col-md-6 {
    width: 50%;
    float: left;
  }
  .orangeText h1 {
    margin-top: 5pt !important;
  }
  p {
    line-height: normal !important;
  }
  .well {
    page-break-inside: avoid;
    box-shadow: none;
    border: none;
    background: lightblue !important;
    margin-bottom: 10px;
  }
  h4 {
    margin-top: 15px;
  }
  .well h6 {
    margin: 0 !important;
  }
  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td {
    padding-top: 5pt;
    padding-bottom: 5pt;
  }
  .container.fullPrint {
    width: 100% !important;
  }
  .container.fullPrint > .printRow {
    margin: 0 !important;
    display: flex !important;
  }
  .container.fullPrint > .printRow > .col-md-5 {
    flex-basis: 50%;
  }
  .container.fullPrint > .printRow > .col-md-7 {
    flex-basis: 50%;
  }
  .container.fullPrint a[href]:after {
    content: none !important;
  }
  .innerUiSettingsCard {
    /* border: none; */
    -webkit-box-shadow: none;
    box-shadow: none;
    /* background: #eaeef1; */
    border-radius: 0;
    border: solid #eaeef1 1px;
  }
}

#ReportPage {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-size: 11pt;
}

#ReportPage table.blankStyle,
#ReportPage table.blankStyle tr,
#ReportPage table.blankStyle tr td {
  border: none !important;
}

#ReportPage table.blankStyle tr td {
  padding: 1.5pt 3pt;
}

#ReportPage .mainReportTable {
  border: 1pt solid #666;
  width: 100%;
  margin-top: 10pt;
  box-sizing: border-box;
}

#ReportPage .mainReportTable > tbody > tr + tr {
  border-top: 1pt solid #666;
}

#ReportPage .styledReport tr td,
#ReportPage .styledReport tr th {
  padding: 3pt 5pt !important;
}

#ReportPage .styledReport {
  margin-top: 7pt;
}

#ReportPage .styledReport thead tr th {
  background-color: #eee !important;
  font-size: 13pt;
}

#ReportPage .styledReport tbody tr td:nth-last-child(1),
#ReportPage .styledReport tbody tr td:nth-last-child(2),
#ReportPage .styledReport thead tr th:nth-last-child(1),
#ReportPage .styledReport thead tr th:nth-last-child(2),
#ReportPage .styledReport tfoot tr td:nth-last-child(1),
#ReportPage .styledReport tfoot tr td:nth-last-child(2) {
  text-align: right;
}

#ReportPage .styledReport tfoot tr:first-child {
  border-top: 1pt solid #999;
}

#ReportPage .styledReport tfoot tr:last-child td {
  font-size: 13pt;
  font-weight: bold;
}

.highlighted {
  background-color: #ff6347;
}

.not-allowed {
  cursor: not-allowed !important;
}

.YourHighlightClass {
  background: none;
  font-weight: bold;
}

.rbc-event {
  border: none !important;
  background: transparent !important;
  text-align: center;
  cursor: not-allowed;
}

.rbc-event-label {
  display: none;
}

#order-form {
  font-size: 14px;
}

#order-form label {
  font-size: 14px;
}

#order-form .title {
  font-size: 20px;
}

.justify-content-between {
  justify-content: space-between;
}

.loadingSpinner {
  font-size: 20px;
  vertical-align: middle;
  animation: spinIt infinite linear 1s;
  -webkit-animation: spinIt infinite linear 1s;
}

@keyframes spinIt {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.disableDropzone {
  position: relative;
}

.disableDropzone:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 10px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.15);
  cursor: not-allowed;
}

.trmoving > div {
  background-color: white;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.25);
  width: 100% !important;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.trmoving > div.-even {
  background-color: #f5f5f5;
}
.toggleBtn {
  position: absolute;
  top: 0;
  right: 0;
}
